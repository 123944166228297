export enum BookingOption {
  BookingVideo = 'video',
  BookingPhone = 'phone'
}

export interface ConsultationSlot {
  id: string;
  startAt: Date;
  dayTime: string;
}

export interface LawyerAvailabilityDaySlots {
  day: string;
  slots: ConsultationSlot[];
}
