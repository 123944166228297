import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

interface AlertProps {
  type?: 'info' | 'success' | 'warning';
  title?: string;
  className?: string;
  children: React.ReactNode;
}

export default function Alert({
  type = 'info',
  title,
  className = '',
  children
}: AlertProps) {
  return (
    <div
      className={classNames(
        'rounded-md p-4 shadow-base',
        {
          'bg-white': type === 'success',
          'border border-red-400 text-red-800': type === 'warning',
          'bg-blue-100': type === 'info'
        },
        className
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0 mt-[2px]">
          {type === 'success' && (
            <CheckCircleIcon
              className="h-5 w-5 text-green-800"
              aria-hidden="true"
            />
          )}
          {type === 'warning' && (
            <XCircleIcon className="h-5 w-5 text-red-800" aria-hidden="true" />
          )}
        </div>
        <div className="ml-3 w-full">
          {title ? (
            <h3 className="text-base font-medium p-0 mb-2">{title}</h3>
          ) : null}
          <div className="text-sm w-full">{children}</div>
        </div>
      </div>
    </div>
  );
}
