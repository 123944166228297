import { useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query/src/types';

import {
  Lawyer,
  getAuthenticatedLawyerInfo
} from '../../../services/lawyers/info';

export function useLawyerQuery(): UseQueryResult<Lawyer> {
  return useQuery({
    queryKey: ['authenticated-lawyer-info'],
    queryFn: () => getAuthenticatedLawyerInfo()
  });
}
