/* eslint-disable no-plusplus */

/* eslint-disable no-await-in-loop */
import { apiPrivatePythonClient } from './axios';

// We cant paginate for now, so we use a big page size
export const DEFAULT_QUESTIONS_PAGE_SIZE = 200;

export type RequestStatus =
  | 'processing'
  | 'awaiting_answer'
  | 'answered'
  | 'archived'
  | 'consultation_scheduled'
  | 'consultation_to_reschedule'
  | 'consultation_completed'
  | 'consultation_cancelled';

export type QuestionStatus =
  | 'awaiting_payment'
  | 'awaiting_manual_check'
  | 'pending_qualification'
  | 'assigned'
  | 'assignment_rejected'
  | 'assignment_accepted'
  | 'answered'
  | 'answer_validated'
  | 'additional_request_in_progress'
  | 'additional_request_answered'
  | 'reviewed'
  | 'archived';

export interface UnassignedQuestion {
  id: number;
  content: string;
  created_at: string;
  assigned_at: string | null;
  status: QuestionStatus;
  request_status: RequestStatus;
  law_domain_id: string | null;
  tag_id: string | null;
  remaining_time_to_answer_seconds: number | null;
  answer_deadline_at: string;
  can_accept_reject: boolean;
}

export interface LawyerQuestion extends UnassignedQuestion {
  customer_id: number | null;
  member_id: number;
  member_full_name: string;
  ai_suggestion: string | null;
  answer_id: number | null;
  consultation_id: number | null;
  note: number | null;
  can_answer: boolean;
  answer_content: string;
  published_at: string;
}

export type GetMemberQuestionsPayload = {
  status?: QuestionStatus[];
  page?: number;
  pageSize?: number;
  orderBy?: '-created_at' | '+created_at';
};

export type GetLawyerQuestionsResponse = {
  total: number;
  items: Array<LawyerQuestion | UnassignedQuestion>;
};

export async function getLawyerQuestions({
  status,
  page = 1,
  pageSize = DEFAULT_QUESTIONS_PAGE_SIZE,
  orderBy = '+created_at'
}: GetMemberQuestionsPayload): Promise<GetLawyerQuestionsResponse> {
  const { data: initialData } =
    await apiPrivatePythonClient.get<GetLawyerQuestionsResponse>(
      '/lawyer/questions',
      {
        params: {
          status,
          page,
          page_size: pageSize,
          order_by: orderBy
        }
      }
    );

  const nbOfPagesToRequest = Math.ceil(initialData.total / 200);

  // To remove when search is implemented in the backend
  if (status?.includes('answered')) {
    for (
      let requestedPage = 2;
      requestedPage <= nbOfPagesToRequest;
      requestedPage++
    ) {
      const { data } = await apiPrivatePythonClient.get('/lawyer/questions', {
        params: {
          status,
          page: requestedPage,
          page_size: pageSize,
          order_by: orderBy
        }
      });

      initialData.items.push(...data.items);
    }
  }

  return initialData;
}

export async function getLawyerQuestion(
  questionId: number
): Promise<LawyerQuestion> {
  const { data } = await apiPrivatePythonClient.get<LawyerQuestion>(
    `/lawyer/questions/${questionId}`
  );

  return data;
}
