import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ConsultationsFilter } from 'types/consultation';
import { SetState } from 'types/core';

export default function ConsultationsNav({
  filter,
  setFilter,
  scheduledLength,
  toCompleteLength,
  pastLength
}: {
  filter: ConsultationsFilter;
  setFilter: SetState<ConsultationsFilter>;
  scheduledLength?: number;
  toCompleteLength?: number;
  pastLength?: number;
}) {
  const { t } = useTranslation('lawyer');

  const tabs = useMemo(
    () => [
      {
        name: ConsultationsFilter.SCHEDULED,
        current: true,
        length: scheduledLength
      },
      {
        name: ConsultationsFilter.TOCOMPLETE,
        current: false,
        length: toCompleteLength
      },
      {
        name: ConsultationsFilter.PAST,
        current: false,
        length: pastLength
      }
    ],
    [pastLength, scheduledLength, toCompleteLength]
  );

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <div className="mb-4">
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded"
            onChange={(event) =>
              setFilter(event.target.value as ConsultationsFilter)
            }
            defaultValue={tabs.find((tab) => tab.current)!.name}
          >
            {tabs.map((tab) => (
              <option key={tab.name} value={tab.name}>
                {t(`account.consultations.tabs.${tab.name}`)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="hidden sm:block">
        <div>
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                type="button"
                onClick={() => setFilter(tab.name)}
                key={tab.name}
                className={classNames(
                  filter === tab.name
                    ? 'border-purple-900 font-bold'
                    : 'border-transparent text-gray-800',
                  'group border-b-2 inline-flex items-center py-4 px-1 font-medium text-sm'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                <span className="text-base pr-2">
                  {t(`account.consultations.tabs.${tab.name}`)}
                </span>
                <span
                  className={classNames(
                    filter === tab.name
                      ? 'border border-purple-900 text-purple-900 rounded-[32px] px-2'
                      : 'border border-gray-700 text-gray-700 rounded-[32px] px-2'
                  )}
                >
                  {tab.length}
                </span>
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
