export default function LegendItem({
  colorClass,
  label
}: {
  colorClass: string;
  label: string;
}) {
  return (
    <div className="flex items-center space-x-2">
      <div className={`w-10 h-6 rounded ${colorClass}`} />
      <span className="text-sm font-medium">{label}</span>
    </div>
  );
}
