import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { LawyerQuestion, UnassignedQuestion } from 'services/lawyerQuestion';

import { formatDateToIntl } from 'utils/date';

import { ToAnswerDateIndicator } from '../shared';

export default function QuestionAssignationCard({
  question
}: {
  question: UnassignedQuestion | LawyerQuestion;
}) {
  const [searchParams] = useSearchParams();

  const isSelected = Number(searchParams.get('id')) === question.id;

  return (
    <div
      className={classNames(
        'pt-6 pl-8 pr-6 relative',
        isSelected
          ? 'bg-purple-100 border-l-4 border-purple-900'
          : 'border-l-4 border-white bg-white'
      )}
    >
      <div>
        <div className="flex justify-end pb-2">
          <span className="text-gray-700 text-sm flex-shrink-0">
            {formatDateToIntl(new Date(question.created_at))}
          </span>
        </div>
        <div className="px-2">
          <div className="pb-4">
            <span className="text-gray-700 text-sm break-words line-clamp-2">
              {question.content}
            </span>
          </div>
          <ToAnswerDateIndicator answerDeadLine={question.answer_deadline_at} />
        </div>
        <div className="px-4 pt-6">
          <div className="border-b border-gray-500" />
        </div>
      </div>
    </div>
  );
}
