import { apiPrivatePythonClient } from '../axios';

export type Lawyer = {
  id: number;
  email: string;
  first_name: string | null;
  last_name: string | null;
  can_access_ai_suggestion_answer: boolean;
  phone_cellphone: string | null;
};

export async function getAuthenticatedLawyerInfo(): Promise<Lawyer> {
  const res = await apiPrivatePythonClient.get(`/lawyer/lawyers/me`);
  return res.data;
}
