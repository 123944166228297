import type { Customer } from './customer';

export enum ConsultationStatus {
  PENDING = 'status_pending',
  VALIDATED = 'status_validated',
  QUALIFIED = 'status_qualified',
  SCHEDULED = 'status_scheduled',
  SCHEDULED_APPOINTMENT = 'status_appointment_scheduled',
  HAS_INCIDENT = 'status_has_incident',
  TO_RESCHEDULE = 'status_to_reschedule',
  COMPLETED = 'status_completed',
  CANCELLED = 'status_cancelled'
}

export interface ConsultationFile {
  file: any;
  name: string;
  size: number;
  content: string;
}

export interface ApiConsultationConfirmation {
  id: number;
  questionId: number;
  status: Status;
  date: Date;
  customer: Customer;
  comment: null;
  document: null;
}

export interface Status {
  '@fqType': string;
  value: string;
}

export enum ConsultationCancelIntent {
  /*
   * Incidents caused by the lawyer
   */
  LAWYER_UNAVAILABLE = 10,
  /** Lawyer is not able to process this case */
  LAWYER_RECUSED = 11,
  /** Lawyer didn't show up */
  LAWYER_NOSHOW = 12,
  /*
   * Incidents caused by the customer
   */
  /** The customer was not available at the time of appointment */
  CUSTOMER_UNAVAILABLE = 20,
  /** Customer is not satisfied, e.g. because the lawyer did not provide useful informations */
  CUSTOMER_DISPUTE = 21,
  /** Lawyer attempted to call the customer, but was unable to */
  CUSTOMER_NOSHOW = 22,
  /** The order was cancelled, thus leading to the consultation cancellation */
  // CUSTOMER_ORDER_CANCELLATION = 23,
  /** The appointment has been cancelled due to an agent error */
  // ASSIGNMENT_ERROR = 98,
  /** Other incident */
  OTHER = 99
}

export enum ConsultationsFilter {
  SCHEDULED = 'scheduled',
  TOCOMPLETE = 'toComplete',
  PAST = 'past'
}
