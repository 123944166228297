import { useQueries, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { LawyerQuestion, getLawyerQuestions } from 'services/lawyerQuestion';

import { useQuestionsPageSearch } from 'hooks';

import { QuestionsNav } from 'features/lawyer/account/questions/nav';

const LawyerQuestionsPage = () => {
  const { pathname } = useLocation();
  const [search, setSearch] = useState<string>('');

  const [
    { data: questionsAssigned, isLoading: isQuestionsAssignedLoading },
    { data: questionsAccepted, isLoading: isQuestionsAcceptedLoading }
  ] = useQueries({
    queries: [
      {
        queryKey: ['lawyer-questions-assigned'],
        queryFn: () => getLawyerQuestions({ status: ['assigned'] }),
        refetchOnWindowFocus: true
      },
      {
        queryKey: ['lawyer-questions-accepted'],
        queryFn: () =>
          getLawyerQuestions({
            status: ['assignment_accepted']
          }),
        refetchOnWindowFocus: true
      }
    ]
  });

  const { data: questionsAnswered, isLoading: isQuestionsAnsweredLoading } =
    useQuery({
      queryKey: ['lawyer-questions-answered'],
      queryFn: () =>
        getLawyerQuestions({
          status: [
            'answered',
            'answer_validated',
            'additional_request_answered',
            'reviewed'
          ],
          orderBy: '-created_at'
        })
    });

  const {
    questionsAcceptedResults,
    questionsAssignedResults,
    questionsAnsweredResults
  } = useQuestionsPageSearch({
    query: search,
    entities: {
      questionsAssigned: questionsAssigned?.items || [],
      questionsAccepted: (questionsAccepted?.items as LawyerQuestion[]) || [],
      questionsAnswered: (questionsAnswered?.items as LawyerQuestion[]) || []
    }
  });

  const shouldRenderOutlet = !(pathname === '/account/questions');

  return (
    <div className="lg:grid lg:grid-cols-12">
      <div
        className={classNames(
          shouldRenderOutlet
            ? 'hidden lg:block lg:col-span-4'
            : 'block col-span-4'
        )}
      >
        <QuestionsNav
          setSearch={setSearch}
          questionsAssigned={questionsAssignedResults}
          questionsAccepted={questionsAcceptedResults}
          questionsAnswered={questionsAnsweredResults}
          isLoading={
            isQuestionsAnsweredLoading ||
            isQuestionsAssignedLoading ||
            isQuestionsAcceptedLoading
          }
        />
      </div>

      <div
        className={classNames(
          shouldRenderOutlet ? 'block col-span-12 lg:col-span-8' : 'hidden'
        )}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default LawyerQuestionsPage;
