import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';
import { Toast } from 'features/shared/toasts';

import { formatDateToIntl, getDateAsHhMm } from 'utils/date';

import { SetState } from 'types/core';

import { Availability } from '../../../../pages/consultation/CalendarPage';
import { deleteAvailability } from '../../../../services/lawyers/calendar';

interface ModalDeleteAvailabilityProps {
  availability: Availability;
  isModalDeleteAvailabilityOpen: boolean;
  setIsModalDeleteAvailabilityOpen: SetState<boolean>;
}

export default function ModalDeleteAvailability({
  availability,
  isModalDeleteAvailabilityOpen,
  setIsModalDeleteAvailabilityOpen
}: ModalDeleteAvailabilityProps) {
  const { t } = useTranslation('lawyer');

  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: (id: string) => deleteAvailability(id),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['lawyer-calendar-events']
      });
      setIsModalDeleteAvailabilityOpen(false);
    },
    onError: () => {
      onCloseModal();
      toast.custom(
        <Toast
          type="error"
          title={t('general.error')}
          message={t('account.availability.delete.error')}
        />,
        { position: 'top-right', duration: 3000 }
      );
    }
  });

  const onCloseModal = () => {
    setIsModalDeleteAvailabilityOpen(false);
  };

  const formattedAvailabilityStartTime = getDateAsHhMm(
    availability.range.start
  );
  const formattedAvailabilityEndTime = getDateAsHhMm(availability.range.end);
  const formattedDay = formatDateToIntl(availability.range.end);

  return (
    <LayoutModal
      isModalOpen={isModalDeleteAvailabilityOpen}
      handleModalClose={onCloseModal}
    >
      <div>
        <div className="relative w-fit flex justify-start items-center pb-4 mt-6">
          <h4 className="text-black font-sans font-bold text-xl">
            {t('account.availability.delete.title', {
              formattedAvailabilityStartTime,
              formattedAvailabilityEndTime,
              formattedDay
            })}
          </h4>
        </div>
        <div className="mt-6">
          <div className="flex gap-4 justify-end">
            <Button
              variant="tertiary"
              size="medium"
              label={t('account.availability.delete')}
              isLoading={isPending}
              onClick={() => mutate(availability.id)}
            />
          </div>
        </div>
      </div>
    </LayoutModal>
  );
}
