import clsx from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LawyerConsultation } from 'services/lawyerConsultation';

import { useLocale } from 'hooks';

import { Button } from 'features/shared/buttons';
import {
  CalendarIcon,
  ClockIcon,
  PhoneIcon,
  QuestionIcon
} from 'features/shared/icons';

import { ConsultationsFilter } from 'types/consultation';

import ConsultationDocumentCardSection from './ConsultationCardDocumentSection';

interface LawyerConsultationCardProps {
  handleCompleteConsultation?: (id: LawyerConsultation['id']) => void;
  onButtonClickConsultation?: (id: LawyerConsultation['id']) => void;
  consultation: LawyerConsultation;
  status: ConsultationsFilter;
  isCompleteConsultationLoading?: boolean;
  showOnly?: boolean;
}

export default function LawyerConsultationCard({
  handleCompleteConsultation,
  onButtonClickConsultation = () => {},
  consultation,
  status,
  isCompleteConsultationLoading,
  showOnly = false
}: LawyerConsultationCardProps) {
  const { locale } = useLocale();
  const { t } = useTranslation('lawyer');

  const isScheduled = ConsultationsFilter.SCHEDULED === status;

  const triggerhandleCompleteConsultation = useCallback(() => {
    if (handleCompleteConsultation) handleCompleteConsultation(consultation.id);
  }, [handleCompleteConsultation, consultation]);

  const formattedAppointmentDate = new Intl.DateTimeFormat(locale, {
    dateStyle: 'full'
  }).format(new Date(consultation.start_at));

  const formattedAppointmentTime = new Intl.DateTimeFormat(locale, {
    timeStyle: 'short'
  }).format(new Date(consultation.start_at));

  return (
    <div className="md:max-w-lg bg-white rounded-lg">
      <div
        id="top-section"
        className={clsx(
          'px-6 py-3 rounded-t-lg',
          isScheduled || showOnly ? 'bg-purple-400' : 'bg-gray-500'
        )}
      >
        <div className="flex gap-8">
          <div className="flex gap-2 items-center">
            <CalendarIcon fill={isScheduled ? '#8C79E6' : '#545454'} />
            <span className="capitalize">{formattedAppointmentDate}</span>
          </div>
          <div className="flex gap-2 items-center">
            <ClockIcon fill={isScheduled ? '#8C79E6' : '#545454'} />
            <span className="capitalize">{formattedAppointmentTime}</span>
          </div>
        </div>
      </div>
      <div id="content-section" className="p-6">
        <div id="question-section" className="flex items-start">
          <div className="flex items-center gap-2">
            <QuestionIcon className="w-6 h-6" />
          </div>
          <div className="ml-3 w-full flex flex-col gap-4">
            <div className="md:flex md:flex-col md:items-start">
              <Link
                to={`/account/questions/questions-answered/${consultation.question_id}`}
              >
                <span className="underline font-bold text-sm">
                  {t('account.consultations.card.associatedQuestion')}
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className="my-4 border-b border-[#E3E3E3]" />
        {consultation.document && (
          <>
            <ConsultationDocumentCardSection document={consultation.document} />
            <div className="my-4 border-b border-[#E3E3E3]" />
          </>
        )}

        <div id="contact-section" className="flex items-start">
          <div className="flex items-center gap-2">
            <PhoneIcon />
          </div>
          <div className="ml-3 w-full">
            <div className="flex flex-col items-start pb-2">
              <span className="font-bold text-sm">Contact</span>
            </div>
            <span className="text-sm text-gray-800">
              {`${consultation.member_full_name} - ${consultation.member_phone_cellphone}`}
            </span>
          </div>
        </div>
        <div className="my-4 border-b border-[#E3E3E3]" />
        <div
          className={clsx(
            'flex justify-end',
            consultation.can_complete ? 'mb-2' : ''
          )}
        >
          <div className="flex gap-4">
            {consultation.can_cancel && !showOnly && (
              <Button
                size="medium"
                variant="secondary"
                onClick={() => onButtonClickConsultation(consultation.id)}
                label={t('account.consultations.cancellation.cancel')}
              />
            )}
            {consultation.can_complete && !showOnly && (
              <Button
                size="medium"
                variant="tertiary"
                isLoading={isCompleteConsultationLoading}
                onClick={triggerhandleCompleteConsultation}
                label={t('account.consultations.cancellation.close')}
              />
            )}
          </div>
        </div>

        {consultation.can_complete && !showOnly && (
          <div className="text-right">
            <span className="text-sm text-gray-700">
              {t('account.consultations.toClose.alert')}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
