import { useTranslation } from 'react-i18next';

import LegendItem from './LegendItem';

export default function Legend() {
  const { t } = useTranslation('lawyer');
  return (
    <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-8 pb-6">
      <LegendItem
        colorClass="bg-closedAppointment"
        label={t('account.agenda.consultationFinalized.legend')}
      />
      <LegendItem
        colorClass="bg-scheduledAppointment"
        label={t('account.agenda.consultationScheduled.legend')}
      />
      <LegendItem
        colorClass="bg-availability bg-opacity-30"
        label={t('account.agenda.availability.legend')}
      />
    </div>
  );
}
