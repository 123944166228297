export enum PageName {
  Home = 'home',
  Requests = 'requests',
  PersonalRequests = 'requests-personal',
  CseRequests = 'requests-cse',
  Ask = 'ask',
  Consultations = 'consultations',
  Resources = 'resources',
  Calendar = 'calendar',
  Subscription = 'subscription',
  Guarantees = 'guarantees',
  Contact = 'contact',
  Profile = 'profile',
  Dashboard = 'dashboard',
  LawyerCare = 'lawyercare',
  Collaborators = 'collaborators',
  Settings = 'settings',
  Admin = 'admin'
}

export interface IMenuItem {
  name: PageName;
  href: string;
  current?: boolean;
  icon?: any;
  onClick?: Function;
  subMenus?: IMenuItem[];
}
