import { intervalToDuration, isPast } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { ClockIcon } from 'features/shared/icons';

import { getDateAsDdMmYyyy, getDateAsHhMm } from 'utils/date';

import { LawyerQuestion } from '../../../../../services/lawyerQuestion';

interface ToAnswerDateIndicatorProps {
  answerDeadLine: LawyerQuestion['answer_deadline_at'];
}

const getDurationColor = (hour?: number | undefined) => {
  if (!hour) return { className: 'text-red-800', color: '#EC595B' };
  if (hour < 3) return { className: 'text-red-800', color: '#EC595B' };
  if (hour < 12) return { className: 'text-orange-800', color: '#f97316' };

  return { className: 'text-green-800', color: '#56A663' };
};

export default function ToAnswerDateIndicator({
  answerDeadLine
}: ToAnswerDateIndicatorProps) {
  const { t } = useTranslation('lawyer');
  const day = getDateAsDdMmYyyy(new Date(answerDeadLine));
  const time = getDateAsHhMm(new Date(answerDeadLine));

  const isAnswerOverdue = isPast(new Date(answerDeadLine));
  const durationLeft = intervalToDuration({
    end: new Date(),
    start: new Date(answerDeadLine)
  });

  const durationColor = isAnswerOverdue
    ? getDurationColor()
    : getDurationColor(durationLeft.hours);

  return (
    <div className="flex">
      <div className="pr-2">
        <ClockIcon fill={durationColor.color} />
      </div>
      <div className="-mt-[2px] text-sm">
        <div className="flex items-center justify-start">
          <span className="font-semibold">
            {t('account.questions.answerDeadline.label', {
              day,
              time
            })}
          </span>
        </div>
        <span className={durationColor.className}>
          {isAnswerOverdue
            ? t('account.questions.timeOverdue.label', {
                count: durationLeft.days || 0,
                hours: durationLeft.hours,
                minutes: durationLeft.minutes
              })
            : t('account.questions.timeLeft.label', {
                count: durationLeft.days || 0,
                hours: durationLeft.hours,
                minutes: durationLeft.minutes
              })}
        </span>
      </div>
    </div>
  );
}
