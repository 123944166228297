import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

interface FormHelperProps {
  title: string;
  items?: string[];
  footer?: string;
  variant?: 'transparent' | 'purple';
  defaultOpen?: boolean;
}

function FormHelper({
  children,
  title,
  items,
  variant,
  defaultOpen = false,
  footer
}: PropsWithChildren<FormHelperProps>) {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <DisclosureButton
            className={classNames(
              'flex w-full justify-between rounded-lg font-semibold',
              variant === 'transparent'
                ? 'my-2 text-left'
                : 'text-white  bg-purple-800 px-4 py-2  hover:bg-purple-900'
            )}
          >
            <div className="flex items-center">{title}</div>
            {open ? (
              <MinusIcon className="h-5 w-5" />
            ) : (
              <PlusIcon className="h-5 w-5" />
            )}
          </DisclosureButton>
          <DisclosurePanel className="p-10 py-8 bg-white">
            {children ? (
              <div className="prose text-gray-800 whitespace-pre-line">
                {children}
              </div>
            ) : (
              <ul className="flex flex-col gap-1 list-disc">
                {items?.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            )}
            {footer && <div className="mt-4">{footer}</div>}
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
}

export default FormHelper;
