import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AnsweredIcon } from 'features/shared/icons';

import { getDateAsDdMmYyyy, getDateAsHhMm } from 'utils/date';

import { LawyerQuestionAnswer } from '../../../../../services/answer';

export default function AnsweredDateIndicator({
  answerCreatedDate
}: {
  answerCreatedDate: LawyerQuestionAnswer['published_at'];
}) {
  const { t } = useTranslation('lawyer');

  const formattedAnswerDate = useMemo(() => {
    const day = getDateAsDdMmYyyy(new Date(answerCreatedDate));
    const time = getDateAsHhMm(new Date(answerCreatedDate));
    return `${day} ${t('general.at')} ${time}`;
  }, [answerCreatedDate, t]);

  return (
    <div className="flex items-center justify-start">
      <div className="pr-2">
        <AnsweredIcon />
      </div>
      <span className="text-sm">
        <span className="font-semibold">
          {t('account.questions.answeredThe.label')}
        </span>
        <span className="text-gray-700">{formattedAnswerDate}</span>
      </span>
    </div>
  );
}
