import { Title } from 'components';
import { useTranslation } from 'react-i18next';
import { LawyerQuestionAnswer } from 'services/answer';

import { useLawyer } from 'hooks';

import SanitizedContent from 'features/customer/question/SanitizedContent';
import { AvatarIcon } from 'features/shared/icons';

import { formatDateToIntl, getDateAsHhMm } from 'utils/date';

interface AnswerCardProps {
  answer: LawyerQuestionAnswer;
}

const LawyerAnswerCard = ({ answer }: AnswerCardProps) => {
  const { t } = useTranslation('lawyer');
  const { first_name: firstName, last_name: lastName } = useLawyer();

  const hasFullName = firstName && lastName;

  const getFormattedDate = (publishedDate: Date) => {
    const day = formatDateToIntl(publishedDate);
    const time = getDateAsHhMm(publishedDate);
    return `${day} ${t('general.at')} ${time}`;
  };

  return (
    <div className="bg-white p-6 lg:px-10 lg:py-8 rounded shadow">
      <div className="pb-7 border-gray-500">
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-400">
              <AvatarIcon />
            </span>
            <Title variant="h3">
              {hasFullName
                ? `${firstName} ${lastName}`
                : t('general.ekieClient')}
            </Title>
          </div>
          <span className="text-gray-700 text-sm">
            {getFormattedDate(new Date(answer.published_at))}
          </span>
        </div>
      </div>
      <div className="pt-6 border-t border-gray-500">
        <SanitizedContent content={answer.content} />
      </div>
    </div>
  );
};

export default LawyerAnswerCard;
