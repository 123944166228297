import ProfileMenu from 'components/ProfileMenu';
import { useLocation } from 'react-router-dom';

import { useLawyer } from 'hooks';

import { useAuth } from 'context';

import GearIcon from 'features/shared/icons/GearIcon';

import { IMenuItem, PageName } from 'types/account';

export default function LawyerProfile() {
  const { pathname } = useLocation();
  const { logout } = useAuth();
  const { first_name: firstName, last_name: lastName } = useLawyer();
  const accountTabs: IMenuItem[] = [
    {
      name: PageName.Profile,
      href: '/account/profile',
      current: pathname.includes(PageName.Profile),
      icon: GearIcon
    }
  ];

  return (
    <ProfileMenu
      logout={logout}
      label={(firstName ?? '') + (lastName ?? '')}
      items={accountTabs}
    />
  );
}
