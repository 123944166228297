import * as Accordion from '@radix-ui/react-accordion';
import clsx from 'clsx';
import { PropsWithChildren, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { LawyerQuestion, UnassignedQuestion } from 'services/lawyerQuestion';

import { Chip } from 'features/shared/container';
import { ChevronDownIcon, ChevronUpIcon } from 'features/shared/icons';
import { LayoutLoadingSkeleton } from 'features/shared/layout';

import { SetState } from 'types/core';

import { QuestionMiniature } from '../answered';
import { QuestionAssignationCard } from '../assignations';
import { QuestionsSearch } from './search';

interface QuestionsNavProps {
  setSearch: SetState<string>;
  isLoading: boolean;
  questionsAssigned?: UnassignedQuestion[];
  questionsAccepted?: LawyerQuestion[];
  questionsAnswered?: LawyerQuestion[];
}

interface AccordionChildProps {
  name: string;
  isDisabled?: boolean;
  extra?: ReactNode;
  sectionTitle: string;
  accordionItemOpen: string;
}

export default function QuestionsNav({
  setSearch,
  isLoading,
  questionsAssigned,
  questionsAccepted,
  questionsAnswered
}: QuestionsNavProps) {
  const { pathname } = useLocation();
  const { t } = useTranslation('lawyer');

  const defaultAccordionValue = () => {
    if (pathname.includes('to-answer')) return 'questions-to-answer';
    if (pathname.includes('answered')) return 'answers';
    if (pathname.includes('assignations')) return 'question-assignations';

    return '';
  };

  const [accordionItemOpen, setAccordionItemOpen] = useState<string>(
    defaultAccordionValue()
  );

  return (
    <div className="md:border-r md:border-gray-600">
      <div className="bg-white z-40 sticky top-0">
        <div className="flex flex-col gap-6 pt-8 pb-6 px-6 border-b border-gray-600">
          <QuestionsSearch setSearch={setSearch} />
        </div>
      </div>
      <Accordion.Root
        collapsible
        type="single"
        value={accordionItemOpen}
        onValueChange={setAccordionItemOpen}
        className="pt-2 flex flex-col h-[calc(100vh-111px)] overflow-y-auto"
      >
        <AccordionChild
          name="question-assignations"
          extra={<Chip content={questionsAssigned?.length} />}
          isDisabled={!questionsAssigned?.length}
          accordionItemOpen={accordionItemOpen}
          sectionTitle={t('account.questions.assignations.title')}
        >
          {isLoading && (
            <div className="pt-6 pl-8 pr-6 relative border-l-4 border-white bg-white">
              <LayoutLoadingSkeleton />
            </div>
          )}
          {questionsAssigned?.map((questionAssignation) => (
            <Link
              key={questionAssignation.id}
              to={`/account/questions/question-assignations?id=${questionAssignation.id}&questionId=${questionAssignation.id}`}
            >
              <QuestionAssignationCard question={questionAssignation} />
            </Link>
          ))}
        </AccordionChild>
        <AccordionChild
          name="questions-to-answer"
          extra={<Chip content={questionsAccepted?.length} />}
          isDisabled={!questionsAccepted?.length}
          accordionItemOpen={accordionItemOpen}
          sectionTitle={t('account.questions.toanswer.title')}
        >
          {questionsAccepted?.map((question) => (
            <Link
              key={question.id}
              to={`/account/questions/questions-to-answer?id=${question.id}`}
            >
              <QuestionAssignationCard question={question} />
            </Link>
          ))}
        </AccordionChild>
        <AccordionChild
          name="answers"
          extra={!isLoading && <Chip content={questionsAnswered?.length} />}
          accordionItemOpen={accordionItemOpen}
          sectionTitle={t('account.questions.answered.title')}
        >
          {isLoading && (
            <div className="pt-6 pl-8 pr-6 relative border-l-4 border-white bg-white">
              <LayoutLoadingSkeleton />
            </div>
          )}
          {(questionsAnswered as LawyerQuestion[])?.map((answeredQuestion) => (
            <Link
              key={answeredQuestion.answer_id}
              to={`/account/questions/questions-answered/${answeredQuestion.id}`}
            >
              <QuestionMiniature question={answeredQuestion} />
            </Link>
          ))}
        </AccordionChild>
      </Accordion.Root>
    </div>
  );
}

function AccordionChild({
  children,
  name,
  sectionTitle,
  extra,
  isDisabled,
  accordionItemOpen
}: PropsWithChildren<AccordionChildProps>) {
  return (
    <Accordion.Item value={name} id={name} className="pb-2">
      <Accordion.Header className={clsx(accordionItemOpen === name && 'pb-2')}>
        <Accordion.Trigger
          className="bg-white px-6 py-2 shadow-base w-full"
          disabled={isDisabled}
        >
          <div className="flex justify-between items-center">
            <div className="flex gap-2">
              <div className="flex-shrink-0">
                {accordionItemOpen === name ? (
                  <ChevronDownIcon />
                ) : (
                  <ChevronUpIcon />
                )}
              </div>
              <span className="font-medium text-left">{sectionTitle}</span>
            </div>
            {extra}
          </div>
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>{children}</Accordion.Content>
    </Accordion.Item>
  );
}
