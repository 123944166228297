import * as Sentry from '@sentry/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import jwtDecode from 'jwt-decode';
import { FC, createContext, useContext, useMemo } from 'react';
import {
  AuthenticatedMemberInfo,
  getAuthenticatedMemberInfo
} from 'services/member';
import { useLocalStorage } from 'usehooks-ts';

import { AuthLocalStorage } from 'context/auth/auth.utils';

import { LayoutLoadingSkeleton } from 'features/shared/layout';

import { getAuthenticatedLawyerInfo } from '../services/lawyers/info';

type UserType = 'member' | 'lawyer';

export interface AuthContext {
  userType: UserType | null;
  accessToken: AuthLocalStorage['accessToken'] | null;
  refreshToken: AuthLocalStorage['refreshToken'] | null;
  setAccessToken: React.Dispatch<
    React.SetStateAction<AuthLocalStorage['accessToken']>
  >;
  setRefreshToken: React.Dispatch<
    React.SetStateAction<AuthLocalStorage['refreshToken']>
  >;
  logout: () => void;
  member?: AuthenticatedMemberInfo;
}

const AuthCtx = createContext<AuthContext | undefined>(undefined);

type Jwt = {
  sub: string;
  user_type: UserType;
};

const AuthProvider: FC = ({ children }) => {
  const clientQuery = useQueryClient();
  const [accessToken, setAccessToken, removeAccessToken] = useLocalStorage<
    AuthLocalStorage['accessToken']
  >('accessToken', '', {
    deserializer: (value) => value,
    serializer: (value) => value
  });
  const [refreshToken, setRefreshToken, removeRefreshToken] = useLocalStorage<
    AuthLocalStorage['refreshToken']
  >('refreshToken', '', {
    deserializer: (value) => value,
    serializer: (value) => value
  });

  const { user_type: userType = null } = accessToken
    ? jwtDecode<Jwt>(accessToken)
    : {};

  const { data: member, isLoading: isMemberLoading } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo(),
    enabled: userType === 'member'
  });
  const { data: lawyer, isLoading: isLawyerLoading } = useQuery({
    queryKey: ['authenticated-lawyer-info'],
    queryFn: () => getAuthenticatedLawyerInfo(),
    enabled: userType === 'lawyer'
  });

  if (lawyer) {
    Sentry.setUser({ email: lawyer.email, id: lawyer.id });
  }
  if (member) {
    Sentry.setUser({ email: member.email, id: member.id });
  }

  const logout = () => {
    clientQuery.invalidateQueries({
      queryKey: ['authenticated-member-info'],
      type: 'all'
    });
    clientQuery.invalidateQueries({
      queryKey: ['authenticated-lawyer-info'],
      type: 'all'
    });
    removeAccessToken();
    removeRefreshToken();
    Sentry.getCurrentScope().setUser(null);
  };

  const value = useMemo(
    () => ({
      setAccessToken,
      accessToken,
      refreshToken,
      setRefreshToken,
      member,
      userType,
      logout
    }),
    [member, accessToken, refreshToken]
  );

  if (isLawyerLoading || isMemberLoading) return <LayoutLoadingSkeleton />;

  return <AuthCtx.Provider value={value}>{children}</AuthCtx.Provider>;
};

const useAuth = () => {
  const context = useContext(AuthCtx);
  if (context === undefined) {
    throw new Error('useAuth must be used within a Provider');
  }

  return context;
};

export { AuthProvider, AuthCtx, useAuth };
