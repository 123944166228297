import { useOutletContext } from 'react-router-dom';

import { Subscription } from 'types/subscription';

interface OutletContext {
  subscription: Subscription;
}

export default function useCustomerAccount() {
  return useOutletContext<OutletContext>();
}
