import Fuse from 'fuse.js';
import { LawyerQuestion, UnassignedQuestion } from 'services/lawyerQuestion';

interface UseQuestionsPageSearchProps {
  query: string | null;
  entities: {
    questionsAssigned: UnassignedQuestion[];
    questionsAccepted: LawyerQuestion[];
    questionsAnswered: LawyerQuestion[];
  };
}

interface UseQuestionsPageSearchOutput {
  questionsAssignedResults: UnassignedQuestion[] | [];
  questionsAcceptedResults: LawyerQuestion[] | [];
  questionsAnsweredResults: LawyerQuestion[] | [];
}

const trimStringForSearch = (str: string) => str.replace(/(\r\n|\n|\r)/gm, ' ');
const FUSE_CONFIG = {
  keys: ['content', 'member_full_name', 'answer_content'],
  isCaseSensitive: false,
  includeScore: true,
  includeMatches: true,
  ignoreLocation: true,
  shouldSort: false,
  threshold: 0.2
};

export default function useQuestionsPageSearch({
  query,
  entities: { questionsAssigned, questionsAccepted, questionsAnswered }
}: UseQuestionsPageSearchProps): UseQuestionsPageSearchOutput {
  const QuestionsAssignedFuse = new Fuse(
    questionsAssigned.map((i) => ({
      ...i,
      content: trimStringForSearch(i.content)
    })),
    FUSE_CONFIG
  );

  const QuestionsAcceptedFuse = new Fuse(
    questionsAccepted.map((i) => ({
      ...i,
      content: trimStringForSearch(i.content)
    })),
    FUSE_CONFIG
  );

  const QuestionsAnsweredFuse = new Fuse(
    questionsAnswered.map((i) => ({
      ...i,
      content: trimStringForSearch(i.content)
    })),
    FUSE_CONFIG
  );

  const questionsAssignedResults = query
    ? QuestionsAssignedFuse.search(query).map((result) => result?.item)
    : questionsAssigned;

  const questionsAcceptedResults = query
    ? QuestionsAcceptedFuse.search(query).map((result) => result?.item)
    : questionsAccepted;

  const questionsAnsweredResults = query
    ? QuestionsAnsweredFuse.search(query).map((result) => result?.item)
    : questionsAnswered;

  return {
    questionsAssignedResults,
    questionsAcceptedResults,
    questionsAnsweredResults
  };
}
