import { apiPrivatePythonClient } from './axios';

export type AvailabilitySlot = {
  id: string;
  start_at: Date;
};

export type AvailabilitySlotResponse = {
  slots: AvailabilitySlot[];
};

export async function getQuestionAvailabilitySlots(
  questionId: number
): Promise<AvailabilitySlotResponse> {
  const { data } = await apiPrivatePythonClient.get<AvailabilitySlotResponse>(
    `member/availability-slots`,
    {
      params: { question_id: questionId }
    }
  );

  return data;
}
