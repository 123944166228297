import { StarIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { range } from 'lodash';

interface RatingStarsProps {
  value: number;
  className?: string;
}

const RatingStars = ({ value, className }: RatingStarsProps) => (
  <div className={classNames('flex', className)}>
    {range(5).map((number) => (
      <StarIcon
        key={number}
        className="w-5 h-5"
        strokeWidth={1}
        stroke={value > number ? 'transparent' : '#9B9B9B'}
        fill={value > number ? '#FF993A' : 'transparent'}
      />
    ))}
  </div>
);

export default RatingStars;
