interface NumericValueProps {
  value: number | null;
  suffix?: string;
}

const NumericValue = ({ value, suffix }: NumericValueProps) => (
  <div className="text-3xl font-bold">
    {value !== null ? `${value}${suffix ?? ''}` : '-'}
  </div>
);

export default NumericValue;
