import { privateAxios } from 'context/auth/auth.utils';

import { Customer, NewCustomer } from 'types/customer';
import { NewOnboarding } from 'types/onboarding';

export async function updateCustomer(
  customerId: Customer['id'],
  newCustomer: NewCustomer
): Promise<Customer> {
  const res = await privateAxios.put(`/customers/${customerId}`, newCustomer);
  const { data } = res;
  return data;
}

export async function updateAccount(
  accountId: number,
  accountInput: {
    secondaryEmail?: string;
  }
): Promise<Customer> {
  const res = await privateAxios.put(`/accounts/${accountId}`, accountInput);
  const { data } = res;
  return data;
}

export async function completeCustomer(
  customerId: Customer['id'],
  customer: NewCustomer
): Promise<Customer> {
  const res = await privateAxios.post(
    `/customers/${customerId}/profile-completion`,
    customer
  );
  const { data } = res;
  return data;
}

export async function updateCustomerPassword(
  customerId: Customer['id'],
  newPassword: string
): Promise<Customer> {
  const res = await privateAxios.put(
    `/customers/${customerId}/update-password`,
    {
      newPassword
    }
  );
  const { data } = res;
  return data;
}

export async function createOnboarding(
  customerId: Customer['id'],
  newOnboarding: NewOnboarding
) {
  const res = await privateAxios.post(`/customer-onboarding/${customerId}`, {
    newOnboarding
  });
  const { data } = res;
  return data;
}
