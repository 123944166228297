import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getLawyerQuestion } from 'services/lawyerQuestion';

import {
  ModalQuestionAccepted,
  QuestionAssignationCtas
} from 'features/lawyer/account/questions/assignations';
import { BackQuestions } from 'features/lawyer/account/questions/nav';
import LawyerQuestionCard from 'features/questions/components/LawyerQuestionCard';
import { LayoutLoadingSkeleton } from 'features/shared/layout';

const QuestionAssignationPage = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const questionId = Number(searchParams.get('id'));
  const {
    data: question,
    isLoading: isQuestionLoading,
    isSuccess: isQuestionSuccess
  } = useQuery({
    queryKey: ['lawyer-question', questionId],
    queryFn: () => getLawyerQuestion(questionId),
    refetchOnWindowFocus: true
  });

  const [isModalQuestionAccepted, setIsModalQuestionAccepted] =
    useState<boolean>(false);

  const handleAssignationAccept = () => {
    queryClient.invalidateQueries({
      queryKey: ['lawyer-questions-assigned'],
      refetchType: 'all'
    });
    queryClient.invalidateQueries({
      queryKey: ['lawyer-question', questionId],
      refetchType: 'all'
    });
    queryClient.invalidateQueries({
      queryKey: ['lawyer-questions-accepted'],
      refetchType: 'all'
    });
    setIsModalQuestionAccepted(true);
  };

  const handleAssignationReject = () => {
    queryClient.invalidateQueries({
      queryKey: ['lawyer-questions-assigned'],
      refetchType: 'all'
    });
    queryClient.invalidateQueries({
      queryKey: ['lawyer-questions-accepted'],
      refetchType: 'all'
    });
  };

  if (isQuestionLoading || !isQuestionSuccess) return <LayoutLoadingSkeleton />;

  return (
    <div className="overflow-y-auto max-h-screen">
      <div className="z-40 sticky top-0 bg-gray-paper">
        <div className="pt-6 lg:pt-10 pb-6 px-6 border-b border-gray-500">
          <div className="block pb-4 lg:hidden lg:pb-0">
            <BackQuestions />
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-col gap-4 p-6">
          <div>
            <LawyerQuestionCard question={question} anonymous />
          </div>
          <div>
            <QuestionAssignationCtas
              questionAssignationId={question.id}
              handleAssignationAccept={handleAssignationAccept}
              handleAssignationReject={handleAssignationReject}
            />
          </div>
        </div>
      </div>
      <ModalQuestionAccepted
        isModalQuestionAccepted={isModalQuestionAccepted}
        setIsModalQuestionAccepted={setIsModalQuestionAccepted}
      />
    </div>
  );
};

export default QuestionAssignationPage;
