import { Controller, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BookingStep } from 'pages/consultation/ConsultationBookingPage';

import { Button } from 'features/shared/buttons';

import { SetState } from 'types/core';
import { ConsultationSlot, LawyerAvailabilityDaySlots } from 'types/lawyer';

import VisioLawyerAvailabilitySlots from '../slots/VisioLawyerAvailabilitySlots';

export interface ConsultationSlotInput {
  slot: ConsultationSlot;
}

export default function ConsultationBookingForm({
  lawyerAvailabiltySlots,
  slot,
  setSlot,
  setBookingStep
}: {
  lawyerAvailabiltySlots: LawyerAvailabilityDaySlots[];
  slot?: ConsultationSlot;
  setSlot: SetState<ConsultationSlot>;
  setBookingStep: SetState<BookingStep>;
}) {
  const { t } = useTranslation('customer');
  const { handleSubmit, control } = useForm<ConsultationSlotInput>({
    defaultValues: { slot }
  });

  const onSubmit = (formData: ConsultationSlotInput) => {
    setSlot(formData.slot);
    setBookingStep(BookingStep.Summary);
  };
  const { isDirty } = useFormState({
    control,
    name: 'slot'
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-6 max-w-[720px]">
        <div className="w-full flex flex-col gap-y-4">
          <div className="p-8 w-full h-auto min-h-[428px] bg-white rounded shadow-xl">
            <div className="flex flex-col w-full">
              <h2 className="text-black font-sans font-bold text-xl leading-[24px] mb-5">
                {t('account.consultations.booking.scheduling.title')}
              </h2>
              <Controller
                name="slot"
                control={control}
                render={({ field }) => (
                  <VisioLawyerAvailabilitySlots
                    onSetSlot={field.onChange}
                    currentSlot={field.value}
                    daySlots={lawyerAvailabiltySlots}
                  />
                )}
              />
            </div>
          </div>
          <div className="mb-5">
            <p className="text-gray-700 text-sm text-center">
              {t('account.consultations.booking.scheduling.agreement')}
            </p>
          </div>
          <div className="flex w-full px-8">
            <Button
              variant="primary"
              size="large"
              fullWidth
              submit
              disabled={!isDirty}
              label={t('general.continue')}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
