import { useQuery } from '@tanstack/react-query';
import { Section, Title } from 'components';
import QuestionSectionSkeleton from 'components/skeleton/QuestionSectionSkeleton';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { getMemberConsultations } from 'services/consultation';
import { QuestionSource } from 'services/question';

import { ConsultationStatus } from 'types/consultation';

import ConsultationCard from './components/ConsultationCard';

interface ScheduledConsultationsProps {
  source?: QuestionSource;
}

const ScheduledConsultations = ({
  source = 'personal'
}: ScheduledConsultationsProps) => {
  const { t } = useTranslation('customer');

  const { data, isLoading, isSuccess } = useQuery({
    queryKey: ['member-scheduled-consultations', source],
    queryFn: () =>
      getMemberConsultations({
        status: [ConsultationStatus.SCHEDULED],
        source
      })
  });

  if (isLoading) return <QuestionSectionSkeleton />;
  if (!isSuccess || data.total === 0) return <div />;

  return (
    <Section>
      <Title gutterBottom variant="h2">
        {t('account.consultations.scheduled.title', { count: data.total })}
      </Title>
      <div className="flex flex-col gap-4 max-w-[720px]">
        {data.items.map((consultation) => (
          <NavLink
            to={`/questions/${consultation.question_id}`}
            key={consultation.id}
          >
            <ConsultationCard consultation={consultation} />
          </NavLink>
        ))}
      </div>
    </Section>
  );
};

export default ScheduledConsultations;
