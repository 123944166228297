import { useQuery } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';

import { useSubscriptionQuery } from 'api/customer/queries.index';

import { MemberMenu } from 'features/menu';
import { LayoutLoadingSkeleton } from 'features/shared/layout';

import { getAuthenticatedMemberInfo } from '../../services/member';

export default function AccountPage() {
  const { data: member, isSuccess: isMemberSuccess } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  const subscription = useSubscriptionQuery({
    customerId: member?.id || 0,
    enabled: isMemberSuccess
  });

  if (!subscription.isSuccess) return <LayoutLoadingSkeleton />;

  return (
    <div className="min-h-screen bg-gray-200 md:flex">
      <div
        id="sidebar"
        className="md:my-4 md:ml-2 rounded-2xl bg-white md:w-[256px] md:min-w-[256px]"
      >
        <MemberMenu />
      </div>
      <div
        id="content"
        className="mt-[72px] md:mt-0 md:col-span-2 bg-gray-200 z-10 pt-4 md:pt-4 pb-6 w-full"
      >
        <Outlet
          context={{
            subscription: subscription.data
          }}
        />
      </div>
    </div>
  );
}
