import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { QuestionAnswer, getMemberQuestionAnswer } from 'services/answer';
import {
  Note,
  PublicRateAnswerPayload,
  isPositiveRating,
  rateAnswer
} from 'services/rating';

import { Toast } from 'features/shared/toasts';

import { RateAnswerChoice, RateAnswerComment } from './components';

interface AnswerVoteProps {
  answerId: QuestionAnswer['id'];
  onRateSubmitted?: (note: Note) => void;
}

export type VotingStatus = 'non-voted' | 'unsatisfied' | 'submitted';

export interface RateAnswerInput {
  note: Note;
  comment?: string;
}

const RateAnswer = ({ onRateSubmitted, answerId }: AnswerVoteProps) => {
  const { t } = useTranslation('customer');
  const { mutate, isLoading } = useMutation({
    mutationFn: (data: PublicRateAnswerPayload) => rateAnswer(data),
    onError: () => {
      toast.custom(
        <Toast
          type="error"
          title={t('general.error')}
          message={t('account.rateAnswer.form.error')}
        />,
        { position: 'bottom-left', duration: 3000 }
      );
    }
  });
  const queryClient = useQueryClient();
  const [answerVotingStatus, setAnswerVotingStatus] =
    useState<VotingStatus>('non-voted');
  const [note, setNote] = useState<RateAnswerInput['note']>();
  const { data: answer } = useQuery(['member-question-answer', answerId], () =>
    getMemberQuestionAnswer(answerId as number)
  );

  const onSubmitRateAnswer = (data: RateAnswerInput) => {
    mutate(
      {
        ratingLinkId: answer?.rating_link_id as string,
        ...data
      },
      {
        onSuccess: () => {
          toast.custom(
            <Toast
              type="success"
              title={t('general.success')}
              message={t('account.vote.success')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
          setAnswerVotingStatus('submitted');
          onRateSubmitted?.(data.note);
          queryClient.invalidateQueries({
            queryKey: ['member-question', answer?.question_id]
          });
          queryClient.invalidateQueries({
            queryKey: ['member-question-answer', answerId]
          });
        }
      }
    );
  };

  const onNoteClick = (value: RateAnswerInput['note']) => {
    if (isPositiveRating(value)) {
      return onSubmitRateAnswer({ note: value });
    }

    setNote(value);
    return setAnswerVotingStatus('unsatisfied');
  };

  const onSubmitComment = (comment: string) => {
    note && onSubmitRateAnswer({ note, comment });
  };

  return (
    <>
      {answerVotingStatus === 'non-voted' && (
        <RateAnswerChoice isLoading={isLoading} onClick={onNoteClick} />
      )}
      {answerVotingStatus === 'unsatisfied' && (
        <RateAnswerComment
          isLoading={isLoading}
          onSubmitComment={onSubmitComment}
        />
      )}
    </>
  );
};

export default RateAnswer;
