import { StarIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LawyerQuestion } from 'services/lawyerQuestion';

import { formatDateToIntl } from 'utils/date';

import AnsweredDateIndicator from './AnsweredDateIndicator';

export default function QuestionMiniature({
  question
}: {
  question: LawyerQuestion;
}) {
  const { answerId } = useParams();

  const isSelected = answerId === question.answer_id?.toString();

  return (
    <div
      className={classNames(
        'pt-6 pl-8 pr-6 relative',
        isSelected
          ? 'bg-purple-100 border-l-4 border-purple-900'
          : 'border-l-4 border-white bg-white'
      )}
    >
      <div>
        <div className="flex justify-end pb-2">
          <span className="text-gray-700  text-sm flex-shrink-0">
            {formatDateToIntl(new Date(question.created_at))}
          </span>
        </div>
        <div>
          <div className="pb-4">
            <span className="text-gray-700 text-sm break-words line-clamp-2">
              {question.content}
            </span>
          </div>
          <AnsweredDateIndicator answerCreatedDate={question.published_at} />
          {question.note && (
            <Trans
              parent="div"
              ns="lawyer"
              className="flex items-center text-sm font-semibold mt-1"
              i18nKey="account.questions.rating.label"
              values={{ value: question.note }}
              components={{
                Icon: (
                  <StarIcon
                    className="w-4 h-4 mr-2"
                    strokeWidth={1}
                    stroke="transparent"
                    fill="#FF993A"
                  />
                )
              }}
            />
          )}
        </div>
        <div className="px-4 pt-6">
          <div className="border-b border-gray-500" />
        </div>
      </div>
    </div>
  );
}
