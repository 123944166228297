import { useTranslation } from 'react-i18next';

import { useLocale } from 'hooks';

import { formatDateToIntl } from 'utils/date';

import { Subscription } from 'types/subscription';

import SectionLine from '../dashboard/SectionLine';

const getPerMonthLabel = ({ maxNb }: { maxNb: number | null }) => {
  if (maxNb === 0) return 'general.none';
  if (maxNb === null) return 'general.unlimited';

  return 'funnel.subscription.offer.perMonth';
};

export interface SubscriptionSectionProps {
  subscription: Subscription;
}

export default function SubscriptionDetails({
  subscription: { product, restrictions, legalProtection, ...subscription }
}: SubscriptionSectionProps) {
  const { locale } = useLocale();
  const { t } = useTranslation('customer');

  return (
    <div className="flex w-full flex-col space-y-2">
      <SectionLine title={t('general.subscription')} value={product.name} />
      <SectionLine
        title={t('account.subscription.subscribedOnThe')}
        value={formatDateToIntl(
          new Date(subscription.startDate),
          'long',
          locale
        )}
      />
      <SectionLine
        title={t('account.subscription.restrictionQuestion')}
        value={t(getPerMonthLabel(restrictions.questions), {
          total: restrictions.questions.maxNb
        })}
      />
      <SectionLine
        title={t('account.subscription.restrictionConsultations')}
        value={t(getPerMonthLabel(restrictions.consultations), {
          total: restrictions.consultations.maxNb
        })}
      />
      {legalProtection && (
        <SectionLine
          title={t('account.subscription.legalProtection')}
          value={t('general.yes')}
        />
      )}
    </div>
  );
}
