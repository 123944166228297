/* eslint-disable @typescript-eslint/naming-convention */
import { Switch } from '@headlessui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MEMBER_DEFAULT_REDIRECT_URL } from 'routes/constants';

import { useCreateOnboarding } from 'api/customer/customers';

import { Button } from 'features/shared/buttons';
import {
  FamilyIllustration,
  HabitationIllustration,
  InvestingIllustration,
  SuccessionIllustration,
  TaxesIllustration,
  UmbrellaIllustration
} from 'features/shared/illustrations';

import { getAuthenticatedMemberInfo } from '../../../services/member';

const thematics = [
  {
    id: 1,
    label: 'Immobilier',
    value: 'immobilier',
    icon: <HabitationIllustration className="h-20 w-20" />
  },
  {
    id: 2,
    label: 'Famille',
    value: 'famille',
    icon: <FamilyIllustration className="h-20 w-20" />
  },
  {
    id: 3,
    label: 'Assurance',
    value: 'assurance',
    icon: <UmbrellaIllustration className="h-20 w-20" />
  },
  {
    id: 4,
    label: 'Impôts',
    value: 'impots',
    icon: <TaxesIllustration className="h-20 w-20" />
  },
  {
    id: 5,
    label: 'Héritage',
    value: 'heritage',
    icon: <SuccessionIllustration className="h-20 w-20" />
  },
  {
    id: 6,
    label: 'Investissement',
    value: 'investissement',
    icon: <InvestingIllustration className="h-20 w-20" />
  }
];

export default function SituationCompletionForm({
  handleSkipOnboarding
}: {
  handleSkipOnboarding: () => void;
}) {
  const queryClient = useQueryClient();
  const { data: member } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation('customer');

  const { mutate, isPending } = useCreateOnboarding();

  const [thematicsAnswer, setThematicsAnswer] = useState<any[]>([]);

  const handleChangeThematic = (thematicValue: string) => {
    if (thematicsAnswer.includes(thematicValue)) {
      setThematicsAnswer(
        thematicsAnswer.filter((label) => label !== thematicValue)
      );
    } else {
      setThematicsAnswer([...thematicsAnswer, thematicValue]);
    }
  };

  const handleNavigate = async () => {
    if (!thematicsAnswer.length) {
      handleSkipOnboarding();
      navigate(`${MEMBER_DEFAULT_REDIRECT_URL}?modalOnboarding=true`);
    } else {
      const has_children = searchParams.get('hasChildren')
        ? searchParams.get('hasChildren') === 'true'
        : null;
      const is_owner = searchParams.get('isOwner')
        ? searchParams.get('isOwner') === 'true'
        : null;
      const is_married = searchParams.get('isMarried')
        ? searchParams.get('isMarried') === 'true'
        : null;

      mutate(
        {
          memberId: member!.id,
          newOnboarding: {
            questions: {
              has_children,
              is_owner,
              is_married
            },
            ...(thematicsAnswer.length && {
              themes: thematicsAnswer.map((answer: string) => answer)
            })
          }
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: ['authenticated-member-info'],
              refetchType: 'all'
            });
            navigate('/account/home?modalOnboarding=true');
          }
        }
      );
    }
  };

  const handleNavigateBack = () => {
    navigate('/onboarding/situation');
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="grid grid-cols-2 lg:grid-cols-3 lg:grid-rows-2 gap-4">
        {thematics.map((plan) => (
          <Switch
            key={plan.id}
            checked={thematicsAnswer.includes(plan.value)}
            onChange={() => handleChangeThematic(plan.value)}
            className={({ checked }) =>
              clsx(
                checked &&
                  'border-purple-900 bg-purple-100 drop-shadow-input shadow-purple-900',
                'w-full relative block cursor-pointer rounded-lg bg-white px-6 py-4 focus:outline-none'
              )
            }
          >
            {({ checked }) => (
              <>
                <span>
                  <span className="flex justify-center items-center mb-4">
                    {plan.icon}
                  </span>
                  <span className="text-center w-full block">
                    <span
                      className={clsx(
                        'text-gray-900 text-sm',
                        checked ? 'font-bold' : 'font-medium'
                      )}
                    >
                      {plan.label}
                    </span>
                  </span>
                </span>
                <span
                  className={clsx(
                    checked ? 'border-purple-900' : 'border-gray-500 ',
                    'border pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </Switch>
        ))}
      </div>
      <div className="flex items-center gap-4">
        <div className="w-1/3">
          <Button
            onClick={handleNavigateBack}
            fullWidth
            disabled={isPending}
            submit
            size="medium"
            variant="secondary"
            label={t('general.back')}
          />
        </div>
        <div className="w-2/3">
          <Button
            onClick={handleNavigate}
            fullWidth
            isLoading={isPending}
            submit
            size="medium"
            variant="primary"
            label={t('general.continue')}
          />
        </div>
      </div>
    </div>
  );
}
