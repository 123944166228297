import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Customer, NewCustomer, PreviousCustomer } from 'types/customer';
import { NewOnboarding } from 'types/onboarding';

import {
  completeCustomer,
  createOnboarding,
  updateAccount,
  updateCustomer,
  updateCustomerPassword
} from './api';

export function useUpdateCustomerPasswordMutation() {
  return useMutation({
    mutationFn: ({
      customerId,
      newPassword
    }: {
      customerId: Customer['id'];
      newPassword: string;
    }) => updateCustomerPassword(customerId, newPassword)
  });
}

export function useUpdateCustomerOptimisticMutation({
  prevCustomer
}: {
  prevCustomer: PreviousCustomer;
}) {
  const { id: customerId } = prevCustomer;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newCustomer: NewCustomer) =>
      updateCustomer(customerId, newCustomer),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['authenticated-member-info']
      });
    }
  });
}

export function useUpdateAccountOptimisticMutation(accountId: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => updateAccount(accountId, data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['authenticated-member-info']
      });
    }
  });
}

export function useUpdateOnboardingCustomerOptimisticMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      memberId,
      newCustomer
    }: {
      memberId: number;
      newCustomer: NewCustomer;
    }) => completeCustomer(memberId, newCustomer),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['authenticated-member-info'],
        refetchType: 'all'
      });
    }
  });
}

export function useCreateOnboarding() {
  return useMutation({
    mutationFn: ({
      memberId,
      newOnboarding
    }: {
      memberId: number;
      newOnboarding: NewOnboarding;
    }) => createOnboarding(memberId, newOnboarding)
  });
}
