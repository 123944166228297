import { TabPanel, TabPanels } from '@headlessui/react';
import { useQueries } from '@tanstack/react-query';
import { Divider, Section, Tabs, Title } from 'components';
import { Card, CardContent } from 'components/card';
import { RatingStars } from 'components/statistic';
import { useTranslation } from 'react-i18next';
import {
  getLawyerCurrentMonthPerformances,
  getLawyerOverallPerformances
} from 'services/lawyers/performances';

import { PageLayout } from 'features/customer/account/content';
import { RefreshIcon } from 'features/shared/icons';

import { formatDateToIntl, getDateAsHhMm } from 'utils/date';

import LawyerPerformances from '../../components/statistic/LawyerPerformances';
import { LoadingSpinner } from '../../features/shared/loading';
import {
  getLawyerAnswerRatings,
  getLawyerConsultationRatings
} from '../../services/lawyers/ratings';

const TABS_MAP = [
  { name: 'account.dashboard.currentMonth.title', id: 'currentMonth' },
  { name: 'account.dashboard.overAll.title', id: 'overAll' }
];

const SkeletonPerformances = Array.from({ length: 5 }, (_, index) => (
  <Card key={index} className="animate-pulse flex flex-row">
    <div className="h-3 w-2/3 mb-3 bg-gray-300 rounded" />
    <div className="h-7 w-1/4 bg-gray-300 rounded" />
  </Card>
));

export default function DashboardPage() {
  const { t } = useTranslation('lawyer');

  const [
    {
      data: currentMonthPerformancesData,
      isLoading: isCurrentMonthPerformancesLoading,
      isFetching: isCurrentMonthPerformancesFetching,
      refetch: refetchCurrentMonth
    },
    {
      data: overAllPerformancesData,
      isLoading: isOverAllPerformancesLoading,
      isFetching: isOverAllPerformancesFetching,
      refetch: refetchOverAll
    },
    { data: answerRatingsData, isLoading: isAnswerRatingsLoading },
    { data: consultationRatingsData, isLoading: isConsultationRatingsLoading }
  ] = useQueries({
    queries: [
      {
        queryKey: ['currentMonthPerformances'],
        queryFn: () => getLawyerCurrentMonthPerformances()
      },
      {
        queryKey: ['overAllPerformances'],
        queryFn: () => getLawyerOverallPerformances()
      },
      {
        queryKey: ['answerRatings'],
        queryFn: () => getLawyerAnswerRatings({ page: 1 })
      },
      {
        queryKey: ['consultationRatings'],
        queryFn: () => getLawyerConsultationRatings({ page: 1 })
      }
    ]
  });

  const onRefetchClick = () => {
    refetchCurrentMonth();
    refetchOverAll();
  };

  return (
    <PageLayout>
      <div className="mt-6 m-4 flex justify-between">
        <Title component="h1" variant="h2">
          {t('account.dashboard.title')}
        </Title>
        <div className="flex mt-2 sm:mt-0">
          <span>
            {t('account.dashboard.date', {
              date: formatDateToIntl(new Date(), 'long'),
              time: getDateAsHhMm(new Date())
            })}
          </span>
          <button
            type="button"
            onClick={onRefetchClick}
            className="w-5 h-5 ml-2 text-purple-800"
          >
            {isCurrentMonthPerformancesFetching ||
            isOverAllPerformancesFetching ? (
              <LoadingSpinner large />
            ) : (
              <RefreshIcon />
            )}
          </button>
        </div>
      </div>
      <Section className="!bg-white !px-5 !pt-2 !rounded-lg" gutterBottom>
        <Tabs
          title={t('account.dashboard.performances.title')}
          items={TABS_MAP.map((item) => ({
            ...item,
            name: t(item.name as any)
          }))}
        >
          <TabPanels className="mt-6">
            <TabPanel className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 gap-4">
              {isCurrentMonthPerformancesLoading ? (
                SkeletonPerformances
              ) : (
                <LawyerPerformances
                  performance={currentMonthPerformancesData!}
                />
              )}
            </TabPanel>
            <TabPanel className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 gap-4">
              {isOverAllPerformancesLoading ? (
                SkeletonPerformances
              ) : (
                <LawyerPerformances performance={overAllPerformancesData!} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Section>
      {!isAnswerRatingsLoading && !isConsultationRatingsLoading && (
        <div className="grid grid-cols-2 gap-4">
          {answerRatingsData && answerRatingsData.length > 0 && (
            <Card>
              <Title variant="h3">
                {t('account.dashboard.lastAnswerRates.title')}
              </Title>
              {answerRatingsData.map((answerRating, index) => (
                <div key={answerRating.id}>
                  {answerRating.comment && (
                    <CardContent>{answerRating.comment}</CardContent>
                  )}
                  <CardContent className="flex !flex-row justify-between">
                    <div className="flex gap-4 items-center">
                      <span className="text-gray-700">
                        {answerRating.member_full_name}
                      </span>
                      <RatingStars value={answerRating.note} />
                    </div>
                  </CardContent>
                  {answerRatingsData.length - 1 !== index && (
                    <Divider className="!py-0" />
                  )}
                </div>
              ))}
            </Card>
          )}
          {consultationRatingsData && consultationRatingsData.length > 0 && (
            <Card>
              <Title variant="h3">
                {t('account.dashboard.lastAppointmentRates.title')}
              </Title>
              {consultationRatingsData.map(
                (consultationAppointmentRating, index) => (
                  <>
                    {consultationAppointmentRating.reason && (
                      <CardContent>
                        {consultationAppointmentRating.reason}
                      </CardContent>
                    )}
                    <CardContent className="flex !flex-row justify-between">
                      <div className="flex gap-4 items-center">
                        <span className="text-gray-700">
                          {consultationAppointmentRating.member_full_name}
                        </span>
                        <RatingStars
                          value={consultationAppointmentRating.note}
                        />
                      </div>
                    </CardContent>
                    {consultationRatingsData.length - 1 !== index && (
                      <Divider className="!py-0" />
                    )}
                  </>
                )
              )}
            </Card>
          )}
        </div>
      )}
    </PageLayout>
  );
}
