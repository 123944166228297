import { ApiConsultationConfirmation } from 'types/consultation';

import { apiPrivatePythonClient } from '../../../services/axios';

export async function rescheduleConsultation(
  consultationId: number,
  startAt: Date
): Promise<void> {
  await apiPrivatePythonClient.put<ApiConsultationConfirmation>(
    `/member/consultations/${consultationId}`,
    {
      start_at: startAt
    }
  );
}

export async function postAvailabilityRequest(
  questionId: number
): Promise<void> {
  await apiPrivatePythonClient.post(`/member/availability-requests`, {
    question_id: questionId
  });
}
