import { useTranslation } from 'react-i18next';

import { Alert } from 'features/shared/alerts';

import { formatDateToIntl } from 'utils/date';

import { Subscription } from 'types/subscription';

export default function SubscriptionStatus({
  subscription
}: {
  subscription: Subscription;
}) {
  const { t } = useTranslation('customer');
  const { active, endDate } = subscription;

  return (
    <Alert
      type={active ? 'success' : 'warning'}
      title={t(
        active ? 'account.subscription.active' : 'account.subscription.inactive'
      )}
    >
      <p>
        {active
          ? t('account.subscription.active.paragraph', {
              productName: subscription.product.name,
              endDate: formatDateToIntl(new Date(endDate))
            })
          : t('account.subscription.inactive.b2b.paragraph', {
              endDate: formatDateToIntl(new Date(endDate))
            })}
      </p>
    </Alert>
  );
}
