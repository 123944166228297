import { apiPrivatePythonClient } from './axios';

type TranslateAnswerResponse = {
  text: string;
};

export async function translateFromFrenchToEnglish(
  text: string
): Promise<TranslateAnswerResponse> {
  const res = await apiPrivatePythonClient.post(`/lawyer/translate`, {
    text,
    source_language_code: 'fr',
    target_language_code: 'en'
  });
  return res.data;
}
