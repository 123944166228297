import * as yup from 'yup';

import { TFunctionTranslationFormValidation } from '../../../../../schemas';

export const assignationRejectSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup.object({
    reason: yup
      .string()
      .required(t('schema.assignationReject.reason.required')),
    message: yup
      .string()
      .max(500, t('schema.assignationReject.message.tooLong'))
  });
