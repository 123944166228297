/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { MDXEditorMethods } from '@mdxeditor/editor';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import TranslatedAnswer from 'containers/answer/TranslatedAnswer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { questionAnswerSchema } from 'schemas';
import {
  LawyerQuestionAnswer,
  saveDraftAnswer,
  submitAnswer
} from 'services/answer';
import { LawyerQuestion } from 'services/lawyerQuestion';

import { useLawyer } from 'hooks';

import {
  ModalAnswerConfirmation,
  ModalAnswerSuccess
} from 'features/lawyer/account/questions/to-answer/modals';
import ModalAnswerTemplateConfirmation from 'features/lawyer/account/questions/to-answer/modals/ModalAnswerTemplateConfirmation';
import { ToAnswerCard } from 'features/lawyer/account/questions/to-answer/to-answer-card';
import { LayoutModal } from 'features/shared/layout';
import { Toast } from 'features/shared/toasts';

export interface LawyerAnswerInput {
  answerContent: LawyerQuestionAnswer['content'];
  formalNoticeProposal: boolean;
}

export default function CreateEditAnswer({
  question,
  draftAnswer
}: {
  question: LawyerQuestion;
  draftAnswer: LawyerQuestionAnswer | undefined;
}) {
  const { t: tForm } = useTranslation('form');
  const queryClient = useQueryClient();
  const ref = useRef<MDXEditorMethods>(null);
  const firstRender = useRef(true);

  const { first_name: firstName, last_name: lastName } = useLawyer();

  const [isModalAnswerConfirmationOpen, setIsModalAnswerConfirmationOpen] =
    useState(false);

  const [isModalTemplateConfirmationOpen, setIsModalTemplateConfirmationOpen] =
    useState(false);

  const [isTranslateAnswerOpen, setIsTranslateAnswerOpen] = useState(false);
  const [isModalAnswerSuccessOpen, setIsModalAnswerSuccessOpen] =
    useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    mutate: saveAnswer,
    isPending: isSaveAnswerLoading,
    data: currentDraftAnswer
  } = useMutation({
    mutationFn: (data: Omit<LawyerAnswerInput, 'formalNoticeProposal'>) =>
      saveDraftAnswer(question.id, { content: data.answerContent }),
    onError: () => {
      toast.custom(
        <Toast
          type="error"
          title={tForm('template.lawyer.answer.draft.title')}
          message={tForm('template.lawyer.answer.draft.error')}
        />,
        { position: 'top-right', duration: 2000 }
      );
    }
  });

  const {
    mutate: submitDraftAnswer,
    isPending: isSubmitAnswerLoading,
    isSuccess: isSubmitAnswerSuccess
  } = useMutation({
    mutationFn: (data: Omit<LawyerAnswerInput, 'answerContent'>) =>
      submitAnswer(question.id, {
        requires_formal_notice_letter: data.formalNoticeProposal
      }),
    onError: () => {
      toast.custom(
        <Toast
          type="error"
          title={tForm('template.lawyer.answer.draft.title')}
          message={tForm('template.lawyer.answer.draft.error')}
        />,
        { position: 'top-right', duration: 2000 }
      );
    }
  });

  const answerContentDefaultValue = tForm('template.lawyer.default', {
    lawyerFullName: `${firstName} ${lastName}`
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
    control,
    formState: { errors, isDirty }
  } = useForm<LawyerAnswerInput>({
    defaultValues: {
      answerContent: answerContentDefaultValue,
      formalNoticeProposal: false
    },
    resolver: yupResolver(questionAnswerSchema(tForm))
  });

  const fieldValue = watch('answerContent');

  useEffect(() => {
    if (question?.id) {
      firstRender.current = true;
      if (draftAnswer?.content) {
        setValue('answerContent', draftAnswer?.content);
        onSaveDraft();
        // Lawyer copy/paste answer from external source
        ref.current?.setMarkdown(
          draftAnswer?.content.replace(/<\!--.*?-->/g, '')
        );
      } else reset();
    }
  }, [question?.id]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (fieldValue.length > 15) {
        if (!firstRender.current) onSaveDraft();
        else firstRender.current = false;
      }
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
  }, [fieldValue]);

  const onSaveDraft = async () => {
    const { answerContent } = getValues();

    await saveAnswer({ answerContent });
  };

  const handleConfirm = async () => {
    const { formalNoticeProposal } = getValues();

    await onSaveDraft();

    submitDraftAnswer(
      { formalNoticeProposal },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: ['lawyer-questions-accepted'],
            refetchType: 'all'
          });
          await queryClient.invalidateQueries({
            queryKey: ['lawyer-questions-answered'],
            refetchType: 'all'
          });
          searchParams.delete('id');
          setSearchParams(searchParams);
          setIsModalAnswerConfirmationOpen(false);
          setIsModalAnswerSuccessOpen(true);
        },
        onSettled: () => {
          setIsModalAnswerConfirmationOpen(false);
        }
      }
    );
  };

  const onChangeDefaultContent = () => {
    setValue('answerContent', answerContentDefaultValue);
    ref.current?.setMarkdown(answerContentDefaultValue);
    setIsModalTemplateConfirmationOpen(false);
  };

  const onChangeTemplateConfirmation = useCallback(() => {
    if (isDirty) setIsModalTemplateConfirmationOpen(true);
    else onChangeDefaultContent();
  }, [onChangeDefaultContent, setIsModalTemplateConfirmationOpen, isDirty]);

  const onSubmit = async () => {
    await onSaveDraft();
    setIsModalAnswerConfirmationOpen(true);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <ToAnswerCard
        onChangeTemplateConfirmation={onChangeTemplateConfirmation}
        onTranslateClick={() => setIsTranslateAnswerOpen(true)}
        isSavingDraftLoading={isSaveAnswerLoading}
        control={control}
        errors={errors}
        ref={ref}
      />
      {!isSubmitAnswerSuccess ? (
        <ModalAnswerConfirmation
          handleConfirm={handleConfirm}
          setIsOpen={setIsModalAnswerConfirmationOpen}
          isOpen={isModalAnswerConfirmationOpen}
          isLoading={isSubmitAnswerLoading}
          hasLegalGrounds={currentDraftAnswer?.has_legal_grounds}
        />
      ) : (
        <ModalAnswerSuccess
          isOpen={isModalAnswerSuccessOpen}
          setIsOpen={setIsModalAnswerSuccessOpen}
        />
      )}
      <ModalAnswerTemplateConfirmation
        handleConfirm={onChangeDefaultContent}
        isOpen={isModalTemplateConfirmationOpen}
        setIsOpen={setIsModalTemplateConfirmationOpen}
      />
      <LayoutModal
        isModalOpen={isTranslateAnswerOpen}
        handleModalClose={() => setIsTranslateAnswerOpen(false)}
      >
        <TranslatedAnswer answer={fieldValue} />
      </LayoutModal>
    </form>
  );
}
