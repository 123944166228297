import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import QuestionRejectForm from 'features/lawyer/account/questions/assignations/QuestionRejectForm';
import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

import { acceptQuestionAssignation } from '../../../../../services/lawyers/assignation';
import { Toast } from '../../../../shared/toasts';

export default function QuestionAssignationCtas({
  questionAssignationId,
  handleAssignationAccept,
  handleAssignationReject
}: {
  questionAssignationId: number;
  handleAssignationAccept: () => void;
  handleAssignationReject: () => void;
}) {
  const { t } = useTranslation('lawyer');
  const [isOpen, setIsOpen] = useState(false);

  const { mutate: acceptMutate, isPending: acceptIsLoading } = useMutation({
    mutationFn: (questionId: number) => acceptQuestionAssignation(questionId)
  });

  const handleAcceptClick = () => {
    acceptMutate(questionAssignationId, {
      onSuccess: () => {
        handleAssignationAccept();
      },
      onError: () => {
        toast.custom(
          <Toast
            type="error"
            title={t('general.error')}
            message={t('account.questions.assignations.error')}
          />
        );
      }
    });
  };

  return (
    <div className="flex justify-end">
      <div className="flex gap-6">
        <Button
          variant="secondary"
          size="large"
          label={t('account.questions.assignations.reject')}
          onClick={() => setIsOpen(true)}
        />
        <Button
          isLoading={acceptIsLoading}
          variant="tertiary"
          size="large"
          label={t('account.questions.assignations.accept')}
          onClick={() => handleAcceptClick()}
        />
        <LayoutModal
          handleModalClose={() => setIsOpen(false)}
          isModalOpen={isOpen}
        >
          <QuestionRejectForm
            questionAssignationId={questionAssignationId}
            onRejectSettled={() => setIsOpen(false)}
            onRejectSuccess={handleAssignationReject}
          />
        </LayoutModal>
      </div>
    </div>
  );
}
