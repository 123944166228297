import { useQuery } from '@tanstack/react-query';
import FormHelper from 'components/FormHelper';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { getLawyerQuestionAnswer } from 'services/answer';
import { getLawyerQuestion } from 'services/lawyerQuestion';

import { BackQuestions } from 'features/lawyer/account/questions/nav';
import { LawyerQuestionCard } from 'features/questions/components';
import { LayoutLoadingSkeleton } from 'features/shared/layout';

import { useLawyer } from '../../../../../hooks';
import CreateEditAnswer from './CreateEditAnswer';

export default function ToAnswerPage() {
  const { can_access_ai_suggestion_answer: canAccessAiSuggestionAnswer } =
    useLawyer();
  const { t } = useTranslation('lawyer');
  const [searchParams] = useSearchParams();
  const questionId = Number(searchParams.get('id'));

  const { data: question, isPending: isQuestionPending } = useQuery({
    queryKey: ['lawyer-question', questionId],
    queryFn: () => getLawyerQuestion(questionId),
    refetchOnWindowFocus: true,
    enabled: !!questionId
  });

  const isAnswerQueryEnabled = !!question && question.answer_id !== null;

  const { data: answer, isPending: isAnswerPending } = useQuery({
    queryKey: ['lawyer-answer', question?.answer_id],
    queryFn: () => getLawyerQuestionAnswer(questionId),
    gcTime: 0,
    enabled: isAnswerQueryEnabled
  });

  if (!questionId) return <div />;
  if (isQuestionPending || (isAnswerQueryEnabled && isAnswerPending))
    return <LayoutLoadingSkeleton />;

  return (
    <div className="overflow-y-auto max-h-screen">
      <div className="z-40 sticky top-0 bg-gray-paper">
        <div className="pt-6 lg:pt-10 pb-6 px-6 border-b border-gray-500">
          <div className="block pb-4 lg:hidden lg:pb-0">
            <BackQuestions />
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        {question && (
          <div className="flex flex-col gap-4 p-6">
            <LawyerQuestionCard question={question} />
            {canAccessAiSuggestionAnswer && question.ai_suggestion ? (
              <FormHelper
                title={t('account.lawyer.question.answer.helper.title')}
              >
                {question.ai_suggestion}
              </FormHelper>
            ) : (
              <FormHelper
                title={t('account.lawyer.question.answer.advices.howTo.title')}
                items={[
                  t('account.lawyer.question.answer.advices.howTo.advice1'),
                  t('account.lawyer.question.answer.advices.howTo.advice2'),
                  t('account.lawyer.question.answer.advices.howTo.advice3'),
                  t('account.lawyer.question.answer.advices.howTo.advice4')
                ]}
              />
            )}
            <CreateEditAnswer question={question} draftAnswer={answer} />
          </div>
        )}
      </div>
    </div>
  );
}
