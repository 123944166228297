import { Title } from 'components';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getLawyerConsultations } from 'services/lawyerConsultation';

import { ConsultationsNav } from 'features/lawyer/account/consultations/nav';
import { PastConsultations } from 'features/lawyer/account/consultations/past';
import { ScheduledConsultations } from 'features/lawyer/account/consultations/scheduled';
import { ConsultationsToComplete } from 'features/lawyer/account/consultations/to-complete';

import { ConsultationStatus, ConsultationsFilter } from 'types/consultation';

export default function ConsultationsPage() {
  const { data: consultationsScheduledResponse } = useQuery({
    queryKey: ['lawyer-consultations-scheduled'],
    queryFn: () =>
      getLawyerConsultations({
        completable: false,
        status: [ConsultationStatus.SCHEDULED]
      })
  });
  const { data: consultationsToCompleteResponse } = useQuery({
    queryKey: ['lawyer-consultations-to-complete'],
    queryFn: () =>
      getLawyerConsultations({
        completable: true,
        status: [ConsultationStatus.SCHEDULED]
      })
  });
  const [filter, setFilter] = useState<ConsultationsFilter>(
    ConsultationsFilter.SCHEDULED
  );

  return (
    <div className="min-h-screen">
      <div className="bg-gray-paper z-10 pt-4 md:pt-12 pb-6">
        <div className="px-4 sm:px-12 lg:px-24">
          <div className="mb-4 md:mb-6">
            <Title variant="h1">Consultations</Title>
          </div>
          <ConsultationsNav
            filter={filter}
            setFilter={setFilter}
            scheduledLength={consultationsScheduledResponse?.total}
            toCompleteLength={consultationsToCompleteResponse?.total}
          />
        </div>
        <div className="mb-6 border-b border-gray-600" />
        <div className="px-4 sm:px-12 lg:px-24">
          {filter === ConsultationsFilter.SCHEDULED && (
            <ScheduledConsultations />
          )}
          {filter === ConsultationsFilter.TOCOMPLETE && (
            <ConsultationsToComplete />
          )}
          {filter === ConsultationsFilter.PAST && <PastConsultations />}
        </div>
      </div>
    </div>
  );
}
