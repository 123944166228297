import { useTranslation } from 'react-i18next';

import { LawyerPerformance } from '../../services/lawyers/performances';
import { NumericValue, RatingValue, Statistic } from './index';

interface LawyerPerformancesProps {
  performance: LawyerPerformance;
}

function LawyerPerformances({ performance }: LawyerPerformancesProps) {
  const { t } = useTranslation('lawyer');

  return (
    <>
      <Statistic
        label={t(`account.dashboard.performances.providedAnswers.title`)}
      >
        <NumericValue value={performance.questions.count} />
      </Statistic>
      <Statistic
        label={t(`account.dashboard.performances.averageAnswersRating.title`)}
      >
        <RatingValue value={performance.questions.average_rating} max={5} />
      </Statistic>
      <Statistic
        label={t(`account.dashboard.performances.onTimeAnswers.title`)}
      >
        <NumericValue
          value={
            performance.questions.sla !== null
              ? performance.questions.sla * 100
              : null
          }
          suffix="%"
        />
      </Statistic>
      <Statistic
        label={t(`account.dashboard.performances.performedConsultations.title`)}
      >
        <NumericValue value={performance.consultations.count} />
      </Statistic>
      <Statistic
        label={t(
          `account.dashboard.performances.averageConsultationsRating.title`
        )}
      >
        <RatingValue value={performance.consultations.average_rating} max={5} />
      </Statistic>
    </>
  );
}

export default LawyerPerformances;
