import { useQuery } from 'react-query';

import { Customer } from 'types/customer';

import { getSubscription } from './api';

export function useSubscriptionQuery({
  customerId,
  enabled = true
}: {
  customerId: Customer['id'];
  enabled?: boolean;
}) {
  return useQuery(['subscription'], () => getSubscription(customerId), {
    enabled
  });
}
