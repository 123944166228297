import { apiPrivatePythonClient } from '../axios';

export type RejectReason =
  | 'wrong_assignation'
  | 'wrong_law_domain'
  | 'multiple_themes'
  | 'no_question'
  | 'other';

export type QuestionRejectInput = {
  question_id: number;
  reason: RejectReason;
  message?: string;
};

export async function rejectQuestionAssignation(
  questionRejectInput: QuestionRejectInput
): Promise<void> {
  await apiPrivatePythonClient.post(
    `/lawyer/questions/${questionRejectInput.question_id}/reject-assignation`,
    {
      reason: questionRejectInput.reason,
      message: questionRejectInput.message
    }
  );
}

export async function acceptQuestionAssignation(
  questionId: number
): Promise<void> {
  await apiPrivatePythonClient.post(
    `/lawyer/questions/${questionId}/accept-assignation`
  );
}
