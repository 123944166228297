import { Divider } from 'components';
import { Card, CardContent } from 'components/card';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';

import { Lawyer } from '../../../services/lawyers/info';

interface ConsultationCtaCardProps {
  questionId: number;
  lawyerId: Lawyer['id'];
}

const BookConsultationCard = ({
  questionId,
  lawyerId
}: ConsultationCtaCardProps) => {
  const { t } = useTranslation('customer');

  return (
    <Card compact>
      <CardContent>
        <Paragraph>{t('account.question.consultation.book.title')}</Paragraph>
        <Divider />
        <Paragraph className="text-sm text-gray-800" gutterBottom>
          {t('account.question.consultation.book.description')}
        </Paragraph>
        <Link
          className="self-end"
          to={`/account/consultations/booking?questionId=${questionId}&lawyerId=${lawyerId}`}
        >
          <Button
            variant="tertiary"
            size="small"
            label={t('account.question.consultation.cta')}
          />
        </Link>
      </CardContent>
    </Card>
  );
};

export default BookConsultationCard;
