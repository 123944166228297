import { Menu } from '@headlessui/react';
import {
  BadgeCheckIcon,
  ChatAltIcon,
  DocumentTextIcon,
  InformationCircleIcon
} from '@heroicons/react/outline';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as EkieLogo } from 'assets/ekie.svg';
import { MobileNav } from 'components';
import LinkNav from 'components/LinkNav';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { getAuthenticatedMemberInfo } from 'services/member';

import { MemberProfileMenu } from 'features/menu';
import { HouseIcon } from 'features/shared/icons';

import { IMenuItem, PageName } from 'types/account';

import SubMenu from './components/SubMenu';

const MEMBER_MENU_ITEMS: IMenuItem[] = [
  {
    name: PageName.Home,
    icon: HouseIcon,
    href: '/account/home'
  },
  {
    name: PageName.Requests,
    icon: ChatAltIcon,
    href: '/account/questions',
    subMenus: [
      {
        name: PageName.PersonalRequests,
        href: '/account/questions'
      },
      {
        name: PageName.CseRequests,
        href: '/account/questions-cse'
      }
    ]
  },
  {
    name: PageName.Resources,
    icon: DocumentTextIcon,
    href: '/account/resources'
  },
  {
    name: PageName.Guarantees,
    icon: BadgeCheckIcon,
    href: '/account/guarantees'
  },
  {
    name: PageName.Contact,
    icon: InformationCircleIcon,
    href: '/account/contact'
  }
];

const MEMBER_MENU_MOBILE_ITEMS: IMenuItem[] = [
  {
    name: PageName.Profile,
    href: '/account/profile'
  },
  {
    name: PageName.Admin,
    href: '/admin/collaborators'
  }
];

const MemberMenu = () => {
  const { t } = useTranslation('customer');
  const { pathname } = useLocation();
  const {
    data: member,
    isLoading,
    isSuccess
  } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  if (isLoading || !isSuccess) return <div />;

  const menuItems = MEMBER_MENU_ITEMS.map((item) => {
    if (item.subMenus && member.is_active_cse_member) {
      return <SubMenu item={item} items={item.subMenus} />;
    }

    return <LinkNav translation="customer" item={item} key={item.name} />;
  });

  return (
    <>
      <div className="hidden md:flex md:fixed flex-col md:min-w-64 items-center justify-between h-full py-4 z-50">
        <div className="w-full md:w-auto md:max-w-lg">
          <div className="md:pb-12 pt-4">
            <Link to="/account/home">
              <EkieLogo />
            </Link>
          </div>
          <div className="flex flex-col justify-between">
            <Menu as="nav" className="flex flex-col w-52">
              <Link
                className="font-bold text-center mb-3 bg-purple-900 hover:bg-purple-9000 text-white rounded text-lg px-4 focus:outline-none sm:py-2"
                to="/account/ask"
              >
                {t('account.sidebar.ask')}
              </Link>
              {menuItems}
            </Menu>
          </div>
        </div>
        <MemberProfileMenu />
      </div>
      <MobileNav
        // Mobile nav is used by lawyer too, we should probably create a new LawyerMobileNav component.
        // this component should be called in AccountLayout instead, so we can have DesktopMenu and MobileMenu.
        isActiveMemberCse={member.is_active_cse_member}
        translation="customer"
        buttonLink="/account/ask"
        accountTabs={[...MEMBER_MENU_ITEMS, ...MEMBER_MENU_MOBILE_ITEMS]
          .filter((item) => item.name !== PageName.Admin || member.is_admin)
          .map((item) => ({
            ...item,
            current: pathname.includes(item.name)
          }))}
      />
    </>
  );
};

export default MemberMenu;
