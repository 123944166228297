import { Title } from 'components';
import { useTranslation } from 'react-i18next';
import { LawyerQuestion } from 'services/lawyerQuestion';

import SanitizedContent from 'features/customer/question/SanitizedContent';
import { AvatarIcon } from 'features/shared/icons';

import { formatDateToIntl, getDateAsHhMm } from 'utils/date';

import { ToAnswerDateIndicator } from '../../lawyer/account/questions/shared';

interface LawyerQuestionCardProps {
  question: LawyerQuestion;
  anonymous?: boolean;
}

const LawyerQuestionCard = ({
  question,
  anonymous = false
}: LawyerQuestionCardProps) => {
  const { t } = useTranslation('lawyer');
  const day = formatDateToIntl(new Date(question.created_at));
  const time = getDateAsHhMm(new Date(question.created_at));

  return (
    <div className="bg-white p-6 lg:px-10 lg:py-8 rounded shadow">
      <div className="pb-7 border-b border-gray-500">
        <div className="flex justify-between items-center">
          {anonymous ? (
            <div className="flex gap-4 items-center">
              <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-400">
                <AvatarIcon />
              </span>
              <Title variant="h3">{t('general.ekieClient')}</Title>
            </div>
          ) : (
            question.member_full_name
          )}
          <span className="text-gray-700 text-sm">{`${day} à ${time}`}</span>
        </div>
      </div>
      <div className="py-6 border-b border-gray-500">
        <SanitizedContent content={question.content} />
      </div>
      {!question.answer_id && (
        <div className="pt-6">
          <ToAnswerDateIndicator answerDeadLine={question.answer_deadline_at} />
        </div>
      )}
    </div>
  );
};

export default LawyerQuestionCard;
