import { startOfMonth } from 'date-fns';

import { apiPrivatePythonClient } from '../axios';

export interface LawyerQuestionsPerformance {
  count: number;
  sla: number | null;
  average_rating: number | null;
}

export interface LawyerConsultationsPerformance {
  count: number;
  average_rating: number | null;
}

export interface LawyerPerformance {
  questions: LawyerQuestionsPerformance;
  consultations: LawyerConsultationsPerformance;
}

export async function getLawyerOverallPerformances(): Promise<LawyerPerformance> {
  const res = await apiPrivatePythonClient.get(`/lawyer/performances/me`);

  return res.data;
}

export async function getLawyerCurrentMonthPerformances(): Promise<LawyerPerformance> {
  const startOfMonthParam = startOfMonth(new Date()).toISOString();
  const res = await apiPrivatePythonClient.get(
    `/lawyer/performances/me?range_from=${startOfMonthParam}`
  );

  return res.data;
}
