import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import SanitizedContent from 'features/customer/question/SanitizedContent';
import { LoadingSpinner } from 'features/shared/loading';

import { translateFromFrenchToEnglish } from '../../services/translate';

interface TranslatedAnswerProps {
  answer: string;
}

const TranslatedAnswer = ({ answer }: TranslatedAnswerProps) => {
  const { t } = useTranslation('lawyer');
  const {
    mutate: translateAnswerMutate,
    data: translateAnswerData,
    isPending,
    isIdle,
    error
  } = useMutation({
    mutationFn: (data: string) => translateFromFrenchToEnglish(data)
  });

  useEffect(
    () => translateAnswerMutate(answer),
    [answer, translateAnswerMutate]
  );

  if (isPending || isIdle) return <LoadingSpinner />;
  if (error)
    return <div>{t('account.lawyer.question.answer.translate.error')}</div>;

  return <SanitizedContent content={translateAnswerData!.text} />;
};

export default TranslatedAnswer;
