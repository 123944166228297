import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postAvailabilityRequest, rescheduleConsultation } from './api';

export function useRescheduleConsultationMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      consultationId,
      startAt
    }: {
      consultationId: number;
      startAt: Date;
    }) => rescheduleConsultation(consultationId, startAt),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['consultations'] });
    }
  });
}

export function useRequestNewLawyerAvailabilityMutation() {
  return useMutation({
    mutationFn: (questionId: number) => postAvailabilityRequest(questionId)
  });
}
