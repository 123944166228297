import { apiPrivatePythonClient } from '../axios';
import { Note } from '../rating';

export type PaginationPayload = {
  page?: number;
  pageSize?: number;
};

export const DEFAULT_RATINGS_PAGE_SIZE = 5;

export type AnswerRating = {
  id: number;
  member_full_name: string;
  note: Note;
  comment: string;
};

export type GetLawyerAnswerRatingsResponse = {
  total: number;
  items: AnswerRating[];
};

export async function getLawyerAnswerRatings({
  page = 1,
  pageSize = DEFAULT_RATINGS_PAGE_SIZE
}: PaginationPayload): Promise<AnswerRating[]> {
  const { data } =
    await apiPrivatePythonClient.get<GetLawyerAnswerRatingsResponse>(
      `/lawyer/answer-ratings`,
      {
        params: {
          page,
          page_size: pageSize
        }
      }
    );

  return data.items;
}

export type ConsultationRating = {
  id: number;
  member_full_name: string;
  note: Note;
  reason: 'no_show' | 'late' | 'unsatisfying_answers' | 'other';
};

export type GetConsultationRatingsResponse = {
  total: number;
  items: ConsultationRating[];
};

export async function getLawyerConsultationRatings({
  page = 1,
  pageSize = DEFAULT_RATINGS_PAGE_SIZE
}: PaginationPayload): Promise<ConsultationRating[]> {
  const { data } =
    await apiPrivatePythonClient.get<GetConsultationRatingsResponse>(
      `/lawyer/appointment-ratings`,
      {
        params: {
          page,
          page_size: pageSize
        }
      }
    );

  return data.items;
}
