import { useLawyerQuery } from 'api/lawyer/lawyers';

import { Lawyer } from '../services/lawyers/info';

export default function useLawyer(): Lawyer {
  const lawyer = useLawyerQuery();
  if (!lawyer.isSuccess) {
    throw Error('Lawyer is not loaded.');
  }

  return lawyer.data;
}
