import { useQuery } from '@tanstack/react-query';
import {
  LawyerConsultation,
  getLawyerConsultation
} from 'services/lawyerConsultation';

import { Button } from 'features/shared/buttons';
import { LayoutLoadingSkeleton, LayoutModal } from 'features/shared/layout';

import { ConsultationsFilter } from 'types/consultation';
import { SetState } from 'types/core';

import LawyerConsultationCard from '../lawyer/account/consultations/card/LawyerConsultationCard';

interface ModalDisplayConsultationProps {
  consultationId: LawyerConsultation['id'];
  isModalDisplayConsultationOpen: boolean;
  setIsModalDisplayConsultationOpen: SetState<boolean>;
}

const ModalLawyerConsultation = ({
  consultationId,
  isModalDisplayConsultationOpen,
  setIsModalDisplayConsultationOpen
}: ModalDisplayConsultationProps) => {
  const {
    data: consultation,
    isLoading,
    isSuccess
  } = useQuery({
    queryKey: ['lawyer-consultation', consultationId],
    queryFn: () => getLawyerConsultation(consultationId)
  });

  const handleModalCloseConsultation = () => {
    setIsModalDisplayConsultationOpen(false);
  };

  return (
    <LayoutModal
      isClosable={false}
      isModalOpen={isModalDisplayConsultationOpen}
      handleModalClose={handleModalCloseConsultation}
      withDefaultPadding={false}
    >
      {isLoading || !isSuccess ? (
        <LayoutLoadingSkeleton />
      ) : (
        <LawyerConsultationCard
          status={ConsultationsFilter.TOCOMPLETE}
          consultation={consultation}
          showOnly
        />
      )}
      <div className="flex px-6 py-3 items-center justify-end gap-x-3 ml-4">
        <div className="group flex relative">
          <Button
            size="medium"
            variant="secondary"
            onClick={handleModalCloseConsultation}
            label="Fermer"
          />
        </div>
      </div>
    </LayoutModal>
  );
};

export default ModalLawyerConsultation;
