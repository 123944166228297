import { useQuery } from '@tanstack/react-query';
import { Pagination } from 'components/pagination';
import { useEffect, useState } from 'react';
import {
  DEFAULT_CONSULTATIONS_PAGE_SIZE,
  getLawyerConsultations
} from 'services/lawyerConsultation';

import { ConsultationStatus, ConsultationsFilter } from 'types/consultation';

import LawyerConsultationCard from '../card/LawyerConsultationCard';

export default function PastConsultations() {
  const [completePage, setCompletePage] = useState(1);
  const {
    data: consultationsCompleteResponse,
    refetch: refetchConsultationsComplete
  } = useQuery({
    queryKey: ['lawyer-consultations-completed', completePage],
    queryFn: () =>
      getLawyerConsultations({
        page: completePage,
        status: [ConsultationStatus.COMPLETED, ConsultationStatus.CANCELLED]
      })
  });

  useEffect(() => {
    refetchConsultationsComplete();
  }, [completePage, refetchConsultationsComplete]);

  return (
    <div className="flex flex-col gap-6">
      {consultationsCompleteResponse?.items.map((consultation) => (
        <LawyerConsultationCard
          status={ConsultationsFilter.PAST}
          key={consultation.id}
          consultation={consultation}
        />
      ))}

      <Pagination
        onRefetch={(count) => {
          setCompletePage(count);
        }}
        pageCount={
          (consultationsCompleteResponse?.total || 1) /
          DEFAULT_CONSULTATIONS_PAGE_SIZE
        }
      />
    </div>
  );
}
