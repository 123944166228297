import { EventInput } from '@fullcalendar/core';

import { apiPrivatePythonClient } from '../axios';

export type LawyerAvailabilities = {
  availabilities: LawyerAvailability[];
};

export type LawyerAppointments = {
  appointments: LawyerAppointment[];
};

export type LawyerAvailability = {
  id: string;
  lawyer_id: number;
  start_at: string;
  end_at: string;
};

export type LawyerAppointment = {
  id: number;
  consultation_id: number;
  start_at: string;
  end_at: string;
  is_processed: boolean;
  title: string;
};

export type AppointmentExtendedProps = {
  consultation_id: number;
  appointment_id: number;
  event_type: 'appointment';
};

export type AvailabilityExtendedProps = {
  availability_id: string;
  event_type: 'availability';
};

export async function getLawyerCalendarEvents(
  rangeFrom: string,
  rangeTo: string
): Promise<EventInput[]> {
  const {
    data: { availabilities }
  } = await apiPrivatePythonClient.get<LawyerAvailabilities>(
    'lawyer/availabilities',
    {
      params: {
        range_from: rangeFrom,
        range_to: rangeTo
      }
    }
  );

  const {
    data: { appointments }
  } = await apiPrivatePythonClient.get<LawyerAppointments>(
    'lawyer/appointments',
    {
      params: {
        range_from: rangeFrom,
        range_to: rangeTo,
        exclude_incidents: true
      }
    }
  );

  const availabilitiesEvents: EventInput[] = availabilities.map(
    (availability) => ({
      // Availability events are mixed with appointments one. We add a prefix to ensure id unicity
      id: `av-${availability.id}`,
      extendedProps: {
        availability_id: availability.id,
        event_type: 'availability'
      } as AvailabilityExtendedProps,
      start: availability.start_at,
      end: availability.end_at,
      display: 'background',
      groupId: 'availability'
    })
  );

  const appointmentsEvents: EventInput[] = appointments.map((appointment) => ({
    // Appointment events are mixed with availability ones. We add a prefix to ensure id unicity
    id: `ap-${appointment.id}`,
    extendedProps: {
      appointment_id: appointment.id,
      consultation_id: appointment.consultation_id,
      event_type: 'appointment'
    } as AppointmentExtendedProps,
    start: appointment.start_at,
    end: appointment.end_at,
    durationEditable: false,
    title: appointment.title,
    color: appointment.is_processed ? '#4caf50' : '#3788d8'
  }));

  return [...availabilitiesEvents, ...appointmentsEvents];
}

export async function deleteAvailability(id: string): Promise<void> {
  await apiPrivatePythonClient.delete(`lawyer/availabilities/${id}`);
}

export async function createAvailability(
  range_from: string,
  range_to: string
): Promise<void> {
  await apiPrivatePythonClient.post(`lawyer/availabilities`, {
    range_from,
    range_to
  });
}
