import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Button } from 'features/shared/buttons';
import { SelectInputGroup } from 'features/shared/forms';

import {
  QuestionRejectInput,
  RejectReason,
  rejectQuestionAssignation
} from '../../../../../services/lawyers/assignation';
import { Toast } from '../../../../shared/toasts';
import { assignationRejectSchema } from './validation';

interface QuestionRejectProps {
  questionAssignationId: number;
  onRejectSettled: () => void;
  onRejectSuccess: () => void;
}

type RejectFormInput = Pick<QuestionRejectInput, 'reason' | 'message'>;

const REASON_OPTIONS: { label: string; value: RejectReason }[] = [
  {
    label: 'options.reason.wrongLawDomain',
    value: 'wrong_law_domain'
  },
  { label: 'options.reason.multipleThemes', value: 'multiple_themes' },
  { label: 'options.reason.noQuestion', value: 'no_question' },
  { label: 'options.reason.other', value: 'other' }
];

const QuestionRejectForm = ({
  questionAssignationId,
  onRejectSuccess,
  onRejectSettled
}: QuestionRejectProps) => {
  const { t } = useTranslation('lawyer');
  const { t: tForm } = useTranslation('form');

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm<RejectFormInput>({
    resolver: yupResolver(assignationRejectSchema(tForm)),
    defaultValues: {
      reason: REASON_OPTIONS[0].value
    }
  });
  const reasonWatch = watch('reason');

  const { mutate: rejectQuestionMutate, isPending } = useMutation({
    mutationFn: (data: QuestionRejectInput) => rejectQuestionAssignation(data)
  });

  const onSubmit = (data: RejectFormInput) =>
    rejectQuestionMutate(
      {
        question_id: questionAssignationId,
        reason: data.reason,
        message: data.reason === 'other' ? data.message : undefined
      },
      {
        onSuccess: () => onRejectSuccess(),
        onError: () => {
          toast.custom(
            <Toast
              type="error"
              title={t('general.error')}
              message={t('account.questions.assignations.error')}
            />
          );
        },
        onSettled: () => onRejectSettled()
      }
    );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-4 mt-8"
    >
      <SelectInputGroup
        options={REASON_OPTIONS.map((option) => ({
          ...option,
          label: tForm(option.label as any)
        }))}
        name="reason"
        label={tForm('label.reason')}
        error={errors.reason}
        register={register}
      />
      {reasonWatch === 'other' && (
        <>
          <label className="input-label">{tForm('label.message')}</label>
          <textarea
            rows={3}
            placeholder={tForm('placeholder.message')}
            {...register('message')}
          />
          {errors.message && (
            <p className="mt-2 text-red-800 mb-4">{errors.message.message}</p>
          )}
        </>
      )}
      <div className="flex gap-6">
        <Button
          isLoading={isPending}
          fullWidth
          submit
          size="medium"
          variant="primary"
          label={t('account.questions.assignations.reject')}
        />
      </div>
    </form>
  );
};

export default QuestionRejectForm;
